import React, {FC} from 'react';
import './App.css';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import CompanyRoute from './components/router/CompanyRoute';
import AuthenticatedRoute from './components/router/AuthenticatedRoute';

import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/views/LandingPage';
import TermsPage from './components/views/LandingPage/TermsPage';
import PrivacyPage from './components/views/LandingPage/PrivacyPage';
import SignInPage from './components/views/SignInPage';
import Companies from './components/views/Companies';
import Time from './components/views/Console/Time';
import PublicLayout from './components/layouts/PublicLayout';
import Console from './components/views/Console';
import Home from './components/views/Console/Home';
import Projects from './components/views/Console/Projects';
import Team from './components/views/Console/Team';
import Reports from './components/views/Console/Reports';
import Manage from './components/views/Console/Manage';
import Project from './components/views/Console/Projects/Project';
import AcceptInvite from './components/views/Invites/AcceptInvite';

import './i18n/config';
import Settings from './components/views/Console/Settings';
import Profile from './components/views/Profile/Profile';
import { useApplicationData } from './components/hooks/useApplicationData';
import PageLoading from './components/common/PageLoading';
import { Alert, Result } from 'antd';
import { useTranslation } from 'react-i18next';

const firebaseConfig = {
    apiKey: "AIzaSyAxzvRfdUCROrR2eg37cOFQCw2M1yqHUqc",
    authDomain: "bygget-f4272.firebaseapp.com",
    databaseURL: "https://bygget-f4272.firebaseio.com",
    projectId: "bygget-f4272",
    storageBucket: "bygget-f4272.appspot.com",
    messagingSenderId: "55079692713",
    appId: "1:55079692713:web:6bc3b7ffddbbecdd72bcb8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const App: FC = () => {
    const [error, loading, data] = useApplicationData();
    const { t } = useTranslation();
    
    if(loading) {
        return <PageLoading />
    }

    if(error) {
        return <Result
            status="error"
            title="Error"
            subTitle={error.message}
        />
    }

    if(data?.maintenance) {
        return <Result
            status="500"
            title={t('application.maintenance.title')}
            subTitle={t('application.maintenance.message')}
        />
    }

    return (
        <Routes>
            <Route path="/" element={<PublicLayout />}>
                <Route path="/" element={<LandingPage />} />
                <Route path="terms" element={<TermsPage />} />
                <Route path="privacy" element={<PrivacyPage />} />
                <Route path="signIn" element={<SignInPage />} />
                <AuthenticatedRoute path="profile" element={<Profile />} redirectTo="/signIn"/>
                <AuthenticatedRoute path="acceptInvite/:invite_id" element={<AcceptInvite />} redirectTo="/signIn"/>
                <AuthenticatedRoute path="companies" element={<Companies />} redirectTo="/signIn"/>
                <AuthenticatedRoute path="console" element={<Console />} redirectTo="/signIn">
                    <CompanyRoute path=":company_key" redirectTo="/signIn">
                        <Route path="/" element={<Home />} />
                        <Route path="time" element={<Time />} />
                        <Route path="projects" element={<Projects />} />
                        <Route path="projects/:project_id" element={<Project />} />
                        <Route path="team" element={<Team />} />
                        <Route path="reports" element={<Reports />} />
                        <Route path="reports/:type" element={<Reports />} />
                        <Route path="manage" element={<Manage />} />
                        <Route path="manage/:type" element={<Manage />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="settings/:type" element={<Settings />} />
                    </CompanyRoute>
                </AuthenticatedRoute>
            </Route>
        </Routes>
    )
};

export default App;