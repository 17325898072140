import { Divider } from 'antd';
import React, {FC} from 'react';
import ReportsFilter from './ReportsFilter';
import ExpenseList from './ExpenseList';

interface ExpensesProps {
    companyKey: string
}

const Expenses: FC<ExpensesProps> = ({companyKey}) => {
    return (
        <>
            <ReportsFilter companyKey={companyKey} type="expenses"/>
            <Divider />
            <ExpenseList companyKey={companyKey}/>
        </>
    )
};
export default Expenses;