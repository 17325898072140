import React, {FC} from 'react';
import { Progress } from 'antd';
import { Project } from '../../../../models/Project';

interface BugetProgressBarProps {
    project: Project
}

const BugetProgressBar: FC<BugetProgressBarProps> = ({project}) => {
    let progressOver: number;
    let progressCurrent: number;
    let progressOverPercent: number;
    
    // Over budget
    if(project.budgetIsOver) {
        progressOver = project.totalSpent - project.budget;
        progressOverPercent = progressOver / project.budget;
        progressCurrent = (1 - progressOverPercent);
    } else {
        progressCurrent = project.totalSpent / project.budget;
    }

    // Convert percent to width
    progressCurrent *= 100;

    return (
        <Progress 
            trailColor={project.budgetIsOver ? 'red' : undefined} 
            percent={progressCurrent} 
            showInfo={false}
        />
    )
};

export default BugetProgressBar;