import { Button, Divider, Form, Input, Modal, Select, Space, Spin, Tooltip } from 'antd';
import React, {FC, useEffect, useState} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useClients } from '../../../../hooks/useClients';
import { Project } from '../../../../models/Project';
import { useTranslation } from 'react-i18next';
import ProjectEditTaskList from './components/ProjectEditTaskList';
import { TaskAssignment } from '../../../../models/TaskAssignment';
import { useTaskAssignments } from '../../../../hooks/useTaskAssignments';
import { ProjectUser } from '../../../../models/ProjectUser';
import ProjectEditUserList from './components/ProjectEditUserList';
import { useProjectUsers } from '../../../../hooks/useProjectUsers';
import ClientEdit from '../../Manage/components/ClientEdit';
import { useCompany } from '../../../../hooks/useCompany';

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface ProjectEditProps {
    visible: boolean,
    companyKey: string,
    item?: Project,
    onClose: () => void,
    createData?: (values: any) => Promise<void>,
    updateData?: (id: string, values: any) => Promise<void>,
    loading: boolean
}

const ProjectEdit: FC<ProjectEditProps> = ({visible, companyKey, item, onClose, createData, updateData, loading}) => {
    const [, loadingCompany, dataCompany] = useCompany(companyKey);
    const [, loadingClients, clients, createClientData, , saveLoadingClient] = useClients(companyKey);
    const [, loadingTaskAssignment, taskAssignments, assignTaskAssignment, updateTaskAssignment, deleteTaskAssignment, saveLoadingTaskAssignment] = useTaskAssignments(companyKey, item?.id);
    const [, loadingProjectUsers, projectUsers, assignProjectUser, updateProjectUser, deleteProjectUser, saveLoadingProjectUser] = useProjectUsers(companyKey, item?.id);
    const [tasks, setTasks] = useState<TaskAssignment[]>([]);
    const [users, setUsers] = useState<ProjectUser[]>([]);
    const [billBy, setBillBy] = useState<string>((item && item.billBy) ? item.billBy : 'tasks');
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if(form) {
            form.resetFields();
        }
    },[item])
    
    const onSubmit = async () => {
        form
        .validateFields()
        .then(async values => {
            Object.keys(values).forEach(key => values[key] === undefined ? delete values[key] : {});

            const existingTaskIds = taskAssignments.map(task => task.id);
            const existingUserIds = projectUsers.map(user => user.id);
            const updateTaskIds = tasks.map(task => task.id);
            const updateUserIds = users.map(user => user.id);
            
            values.activeUsers = updateUserIds;
            values.projectManagers = users.filter(user => user.isProjectManager).map(user => user.id);

            const promiseList: any[] = [];
            let createdProjectId: string;
            if(item && updateData) {
                createdProjectId = item.id;
                await updateData(item.id, values);
                const deleteTaskIds = existingTaskIds.filter(id => !updateTaskIds.includes(id));
                deleteTaskIds.forEach(id =>
                    promiseList.push(
                        deleteTaskAssignment(id)
                    )
                )
                const deleteUserIds = existingUserIds.filter(id => !updateUserIds.includes(id));
                deleteUserIds.forEach(id =>
                    promiseList.push(
                        deleteProjectUser(id)
                    )
                )
            } else if(createData){
                values.status = 'active';
                const docRef: any = await createData(values)
                createdProjectId = docRef.id;
            }
            tasks.forEach(task => 
                promiseList.push(existingTaskIds.includes(task.id) ? 
                    updateTaskAssignment(task.id, {
                        billable: task.billable,
                        hourlyRate: task.hourlyRate ? task.hourlyRate : 0
                    }):
                    assignTaskAssignment(createdProjectId, task.id, {
                        billable: task.billable,
                        hourlyRate: task.hourlyRate ? task.hourlyRate : 0
                    })
                )
            )
            users.forEach(user => 
                promiseList.push(existingUserIds.includes(user.id) ? 
                    updateProjectUser(user.id, {
                        isProjectManager: user.isProjectManager,
                        hourlyRate: user.hourlyRate ? user.hourlyRate : 0,
                    }):
                    assignProjectUser(createdProjectId, user.id, {
                        isProjectManager: user.isProjectManager,
                        hourlyRate: user.hourlyRate ? user.hourlyRate : 0,
                    })
                )
            )
            await Promise.all(promiseList);
            form.resetFields();
            onClose();
        })
        .catch(info => {
            console.log('Validate Failed:', info);
        });
    };
    
    const handleCancel = () => {
        onClose();
    }

    return (
        <Modal 
            title={item ? item.label : t('common.add')} 
            visible={visible} 
            confirmLoading={(loading || saveLoadingTaskAssignment || saveLoadingProjectUser)}
            onCancel={handleCancel}
            okButtonProps={{form:'projectEdit', htmlType: 'submit'}}
        >
            {(loadingCompany|| loadingProjectUsers || loadingTaskAssignment || loadingClients) ? <Spin /> : (
            <Form
                {...layout}
                form={form}
                name="projectEdit"
                initialValues={{
                    ...item,
                    billBy: (item && item.billBy) ? item.billBy : 'tasks',
                    budget: (item && item.budget) ? item.budget : 0,
                }}
                onFinish={onSubmit}
            >
                <Form.Item label={t('projects.clientId.title')}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <Form.Item
                            name="clientId"
                            noStyle
                            rules={[{ required: true, message: t('projects.clientId.placeholder') }]}
                        >
                            <Select
                                placeholder={t('projects.clientId.placeholder')}
                            >
                                {clients.map(client => 
                                    <Option key={client.id} value={client.id}>{client.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Button style={{marginLeft: 5}} icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)} />
                    </div>
                </Form.Item>

                <Form.Item
                    label={t('projects.label.title')}
                    name="label"
                    rules={[{ required: true, message: t('projects.label.message') }]}
                >
                    <Input autoFocus />
                </Form.Item>
                
                <Form.Item
                    label={t('projects.tag.title')}
                    name="tag"
                    extra={dataCompany?.lastProjectTag ? t('projects.tag.lastTag', {tag: dataCompany?.lastProjectTag}) : null}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('projects.description.title')}
                    name="description"
                >
                    <Input.TextArea placeholder={t('common.optional')}/>
                </Form.Item>

                <Divider />

                <Form.Item
                    label={t('projects.billBy.title')}
                    name="billBy"
                >
                    <Select
                        style={{ width: '100%' }}
                        onChange={(value: string) => setBillBy(value)}
                    >
                        {['tasks','people','project','none'].map(billType => 
                            <Option key={billType} value={billType}>{t('projects.billBy.'+billType)}</Option>
                        )}
                    </Select>
                </Form.Item>

                {billBy === 'project' &&
                    <Form.Item
                        label={t('projects.fee.title')}
                        name="fee"
                    >
                        <Input type="number" addonBefore="SEK" placeholder="0" />
                    </Form.Item>
                }

                <Form.Item
                    label={t('projects.budget.title')}
                    name="budget"
                >
                    <Input type="number" addonBefore={t('projects.budget.hours')} placeholder="0" />
                </Form.Item>
            </Form>
            )}
            <Divider />
            <ProjectEditTaskList companyKey={companyKey} item={item} showRate={billBy === 'tasks'} onChangeItems={setTasks}/>
            <Divider />
            <ProjectEditUserList companyKey={companyKey} item={item} showRate={billBy === 'people'} onChangeItems={setUsers}/>
            <ClientEdit visible={isModalVisible} onClose={() => {setIsModalVisible(false)}} createData={createClientData} loading={saveLoadingClient}/>
        </Modal>
    )
};
export default ProjectEdit;