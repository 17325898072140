import React, {FC} from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';
import { useTranslation } from 'react-i18next';

const MainNavigation: FC = () => {
    const { t } = useTranslation();

    return (
        <Menu mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="home"><Link to="/">{t('home.title')}</Link></Menu.Item>
            <UserMenu key="user" style={{float: 'right'}}/>
            <Menu.Item key="overview" style={{float: 'right'}}><Link to="/companies">{t('home.overview')}</Link></Menu.Item>
        </Menu>
    )
}

export default MainNavigation;