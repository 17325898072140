import React, {FC, useEffect, useState} from 'react';
import { Radio, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTasks } from '../../../../../hooks/useTasks';
import { Project } from '../../../../../models/Project';
import { TimeReport } from '../../../../../models/TimeReport';
import { useTaskAssignments } from '../../../../../hooks/useTaskAssignments';
import moment from 'moment';
import { Line, Column } from '@ant-design/charts';

interface ProjectProgressChartProps {
    companyKey: string,
    item: Project,
    reports: TimeReport[]
}

interface ReportDateTotalTime {
    date: string,
    value: number
}

const ProjectProgressChart: FC<ProjectProgressChartProps> = ({companyKey, item, reports}) => {
    const [, loadingTasks, defaultTasks] = useTasks(companyKey);
    const [, loadingTaskAssignments, taskAssignments] = useTaskAssignments(companyKey, item.id);
    const [graphData, setGraphData] = useState<any>();
    const [graphMode, setGraphMode] = useState<string>("progress");
    const { t } = useTranslation();

    useEffect(() => {
        if(reports && defaultTasks && taskAssignments) {
            const reportDates: any[] = [];
            reports.forEach(report => {
                if(!reportDates.includes(report.date)) {
                    reportDates.push(report.date)
                }
            });
            var currentTime = 0;
            const reportDatesValues: any[] = reportDates.map(date => {
                var totalHours: number = 0;
                var totalMinutes: number = 0;
                const taskReports = reports.filter(report => report.date === date);
                taskReports.forEach(taskReport => {
                    totalHours += +taskReport.hours;
                    totalMinutes += +taskReport.minutes;
                });
                var totalTimeMoment = moment.duration(totalHours, 'hours');
                totalTimeMoment.add(totalMinutes, 'minutes');
                currentTime += +totalTimeMoment.asHours();
                return {
                    date: date,
                    value: totalTimeMoment.asHours(),
                    progress: Math.floor(currentTime),
                }
            });
            setGraphData(reportDatesValues);
            
        }
    },[reports, defaultTasks, taskAssignments])

    const lineConfig = {
        data: graphData,
        xField: 'date',
        yField: 'progress',
        point: {
            size: 5,
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        },
        annotations: item.hasBudget && [
            {
                type: 'line',
                start: ['min', item.budget],
                end: ['max', item.budget],
                text: {
                    content: t('projects.budget.title'),
                    position: 'right',
                    offsetY: 18,
                    style: { textAlign: 'right' },
                },
                style: {
                    lineDash: [4, 4],
                },
            },
        ],
    };

    const columnConfig = {
        data: graphData,
        xField: 'date',
        yField: 'value',
        tooltip: {
            fields: ['date', 'value'],
            formatter: (input: any) => {
                return { name: input.date, value: input.value + ' ' + t('reports.totalTime.title').toLowerCase() };
            },
        },
        xAxis: {
            label: {
                formatter: (date: string) => {
                    return moment(date).format('ddd D/M')
                }
            },
        },
        label: {
            position: 'middle',
            layout: [{ type: 'adjust-color' }],
        },
        point: {
            size: 5,
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        }
    };

    if(loadingTasks || loadingTaskAssignments) {
        return <Spin />
    }

    if(!graphData) {
        return null
    }

    return (
        <>
            <Radio.Group onChange={e => setGraphMode(e.target.value)} defaultValue="progress">
                <Radio.Button value="progress">{t('reports.progress')}</Radio.Button>
                <Radio.Button value="hoursPerDay">{t('reports.hoursPerDay')}</Radio.Button>
            </Radio.Group>
            <br/>
            <br/>
            {graphMode === "progress" ? (
                <Line {...lineConfig} />
            ):(
                <Column {...columnConfig} />
            )}
            
        </>
    )
};

export default ProjectProgressChart;