import React, {FC, useState} from 'react';
import { Alert, Button, Table } from 'antd';
import { CheckSquareOutlined, BorderOutlined, EditOutlined } from '@ant-design/icons';
import { ExpenseCategory } from '../../../../models/ExpenseCategory';
import ExpenseCategoryEdit from './ExpenseCategoryEdit';
import { useTranslation } from 'react-i18next';
import { useExpenseCategories } from '../../../../hooks/useExpenseCategories';

interface ExpenseCategoryListProps {
    companyKey: string
}

const ExpenseCategoryList: FC<ExpenseCategoryListProps> = ({companyKey}) => {
    const [error, loading, expenseCategories, createExpenseCategory, updateExpenseCategory, saveLoading] = useExpenseCategories(companyKey);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingExpenseCategory, setEditingExpenseCategory] = useState<ExpenseCategory>();
    const { t } = useTranslation();

    const columns = [
        {
            title: t('manage.expenseCategories.name.title'),
            dataIndex: 'name'
        },
        {
            title: t('manage.expenseCategories.deactivated.title'),
            dataIndex: 'deactivated',
            render: (text: boolean, record: ExpenseCategory) => (
                text ? <CheckSquareOutlined /> : <BorderOutlined />
            )
        },
        {
            dataIndex: 'edit',
            render: (text: boolean, record: ExpenseCategory) => (
                <Button onClick={() => {setEditingExpenseCategory(record); setIsModalVisible(true);}}>
                    {t('common.edit')} <EditOutlined />
                </Button>
            )
        }
    ];
    
    if(error) {
        return (
            <Alert
                message="Error"
                description={error.message}
                type="error"
                showIcon
            />
        )
    }
    
    return (
        <>
            <Table 
                dataSource={expenseCategories}
                columns={columns}
                loading={loading}
                rowKey='id'
                pagination={false}
            />
            <br />
            <Button type="primary" onClick={() => setIsModalVisible(true)}>{t('common.add')}</Button>
            <ExpenseCategoryEdit visible={isModalVisible} item={editingExpenseCategory} onClose={() => {setIsModalVisible(false); setEditingExpenseCategory(undefined)}} createExpenseCategory={createExpenseCategory} updateExpenseCategory={updateExpenseCategory} loading={saveLoading}/>
        </>
    )
};

export default ExpenseCategoryList;