import {useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import { Company } from '../models/Company';

export const useCompany = (companyId: string) => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [data, setData] = useState<Company>();

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .update(values)
        setSaveLoading(false);
    }

    useEffect(() => {
        if(!companyId) {
            setLoading(false);
            return;
        }

        const onSnapshot = (snapshot: any) => {
            setData({
                ...snapshot.data(),
                id: snapshot.id
            });
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .onSnapshot(onSnapshot, (error: any) => {
            setLoading(false);
            setError(error);
        });
    
        return unsubscribe;

    }, [companyId]);

    return [error, loading, data, updateData, saveLoading] as const;
}