import {useEffect, useState} from 'react';
import * as firebase from "firebase/app";

export const useSubscription = (companyId: string) => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [saveLoading, setSaveLoading] = useState(false);

    const createData = async (values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('subscription')
            .add(values)
        setSaveLoading(false);
    }

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('subscription')
            .doc(idToUpdate)
            .update(values)
        setSaveLoading(false);
    }

    const deleteData = async (idToUpdate: string) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('subscription')
            .doc(idToUpdate)
            .delete()
        setSaveLoading(false);
    }

    useEffect(() => {
        const onSnapshot = (snapshot: firebase.firestore.QuerySnapshot) => {
            if(snapshot.size > 0) {
                setData({
                    ...snapshot.docs[0].data(),
                    id: snapshot.docs[0].id
                });
            } else {
                setData(null);
            }
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('subscription')
        .onSnapshot(onSnapshot, (error: any) => {
            setError(error)
        },);

        return unsubscribe;
    }, [companyId]);

    return [error, loading, data, createData, updateData, deleteData, saveLoading] as const;
}

export const hasValidSubscription = (data: any, numberOfTeams: number) => {
    return data && (data.status === "active" || data.status === "trialing") && data.quantity > numberOfTeams;
}