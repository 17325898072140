import {useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import { Company } from '../models/Company';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';

export const useCompanies = () => {
    const [user] = useAuthState(firebase.auth());
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Company[]>([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const { t } = useTranslation();

    const createData = async (values: any) => {
        if(!user) {
            return;
        }
        
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .add({
                ...values,
                activeUsers: [user.uid],
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdByUserId: user.uid,
                createdByUserDisplayName: user.displayName
            })
        setSaveLoading(false);
    }

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(idToUpdate)
            .update(values)
        setSaveLoading(false);
    }

    useEffect(() => {
        if(!user) {
            return;
        }

        const onSnapshot = (snapshot: any) => {
            setData(
                snapshot.docs.map((doc: any) => ({
                    ...doc.data(),
                    id: doc.id
                }))
            );
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .where("activeUsers", "array-contains", user.uid)
        .onSnapshot(onSnapshot, (error: any) => {
            console.log(error);
            
            setError(error)
        },);

        return unsubscribe;
    }, [user]);

    return [error, loading, data, createData, updateData, saveLoading] as const;
}