import React, {FC, useState} from 'react';
import { Alert, Button, Table } from 'antd';
import { CheckSquareOutlined, BorderOutlined, EditOutlined } from '@ant-design/icons';
import { useTasks } from '../../../../hooks/useTasks';
import { Task } from '../../../../models/Task';
import TaskEdit from './TaskEdit';
import { useTranslation } from 'react-i18next';

interface TaskListProps {
    companyKey: string
}

const TaskList: FC<TaskListProps> = ({companyKey}) => {
    const [error, loading, tasks, createTask, updateTask, saveLoading] = useTasks(companyKey);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingTask, setEditingTask] = useState<Task>();
    const { t } = useTranslation();

    const columns = [
        {
            title: t('manage.tasks.name.title'),
            dataIndex: 'name'
        },
        {
            title: t('manage.tasks.isDefault.title'),
            dataIndex: 'isDefault',
            render: (text: boolean, record: Task) => (
                text ? <CheckSquareOutlined /> : <BorderOutlined />
                
            )
        },
        {
            title: t('manage.tasks.defaultHourlyRate.title'),
            dataIndex: 'defaultHourlyRate'
        },
        {
            title: t('manage.tasks.billableByDefault.title'),
            dataIndex: 'billableByDefault',
            render: (text: boolean, record: Task) => (
                text ? <CheckSquareOutlined /> : <BorderOutlined />
            )
        },
        {
            title: t('manage.tasks.deactivated.title'),
            dataIndex: 'deactivated',
            render: (text: boolean, record: Task) => (
                text ? <CheckSquareOutlined /> : <BorderOutlined />
            )
        },
        {
            dataIndex: 'edit',
            render: (text: boolean, record: Task) => (
                <Button onClick={() => {setEditingTask(record); setIsModalVisible(true);}}>
                    {t('common.edit')} <EditOutlined />
                </Button>
            )
        }
    ];
    
    if(error) {
        return (
            <Alert
                message="Error"
                description={error.message}
                type="error"
                showIcon
            />
        )
    }
    
    return (
        <>
            <Table 
                dataSource={tasks}
                columns={columns}
                loading={loading}
                rowKey='id'
                pagination={false}
            />
            <br />
            <Button type="primary" onClick={() => setIsModalVisible(true)}>{t('common.add')}</Button>
            <TaskEdit visible={isModalVisible} task={editingTask} onClose={() => {setIsModalVisible(false); setEditingTask(undefined)}} createTask={createTask} updateTask={updateTask} loading={saveLoading}/>
        </>
    )
};

export default TaskList;