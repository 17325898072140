import {useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import { CompanyTemplate } from '../models/Company';

export const useCompanyTemplates = () => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<CompanyTemplate[]>([]);
    
    useEffect(() => {
        const onSnapshot = (snapshot: any) => {
            setData(
                snapshot.docs.map((doc: any) => ({
                    ...doc.data(),
                    id: doc.id
                }))
            );
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('templates')
        .onSnapshot(onSnapshot, (error: any) => {
            setError(error)
        },);

        return unsubscribe;
    }, []);

    return [error, loading, data] as const;
}