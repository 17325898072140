import React, {FC, useEffect, useState} from 'react';
import { Alert, Statistic, Table } from 'antd';
import { useReports, useReportSummary } from '../../../../hooks/useReports';
import { useSearchParams } from 'react-router-dom';
import { useTasks } from '../../../../hooks/useTasks';
import { useProjects } from '../../../../hooks/useProjects';
import { TimeReport } from '../../../../models/TimeReport';
import { WarningOutlined } from '@ant-design/icons';
import { useClients } from '../../../../hooks/useClients';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useCompanyUsers } from '../../../../hooks/useCompanyUsers';
import { Task } from '../../../../models/Task';
import ReportDateChart from './ReportDateChart';

const dateFormat = 'YYYY-MM-DD';

interface ReportsListProps {
    companyKey: string
}

const ReportsList: FC<ReportsListProps> = ({companyKey}) => {
    let [searchParams] = useSearchParams();
    const [errorReports, loadingReports, reports] = useReports<TimeReport>(companyKey, "timeReports", {
        timeframe: searchParams.has('timeframe') ? searchParams.getAll('timeframe') : [moment().startOf('week').format(dateFormat), moment().endOf('week').format(dateFormat)],
        clients: searchParams.getAll('clients'),
        projects: searchParams.getAll('projects'),
        tasks: searchParams.getAll('tasks'),
        team: searchParams.getAll('team')
    });
    const [, loadingTasks, tasks] = useTasks(companyKey);
    const [, loadingProjects, projects] = useProjects(companyKey);
    const [, loadingClients, clients] = useClients(companyKey);
    const [, loadingCompanyUsers, companyUsers] = useCompanyUsers(companyKey);
    const reportSummary = useReportSummary<TimeReport>(reports);
    const { t } = useTranslation();
    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        if(reports && tasks) {
            const foundTasks: Task[] = [];
            reports.forEach(report => {
                const foundTask = tasks.find(task => task.id === report.taskId);
                if(foundTask && !foundTasks.includes(foundTask)) {
                    foundTasks.push(foundTask);
                }
            })
            setTableData(
                foundTasks.map(task => {
                    const taskReports = reports.filter(report => report.taskId === task.id);
                    var taskTotalTime: number = 0;
                    taskReports.forEach(report => {
                        taskTotalTime += +report.totalTime;
                    })
                    return {
                        ...task,
                        totalTime: taskTotalTime,
                        children: taskReports
                    }
                })
            )
        }
    }, [reports, tasks])

    const columns = [
        {
            title: t('reports.date.title'),
            key: 'id',
            dataIndex: 'date',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {
                        children: <strong>{record.name}</strong>,
                        props: {
                            colSpan: 6,
                        },
                    }
                }
                return text
            },
        },
        {
            title: t('reports.clients.title'),
            dataIndex: 'clientId',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const timeReport = record as TimeReport;
                const project = projects.find(project => project.id === timeReport.projectId);
                if(project) {
                    const client = clients.find(client => client.id === project.clientId);
                    if(client) {
                        return client.name
                    }
                }
                return <WarningOutlined />
            }
        },
        {
            title: t('reports.projects.title'),
            dataIndex: 'projectId',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const timeReport = record as TimeReport;
                const project = projects.find(project => project.id === timeReport.projectId);
                if(project) {
                    return <><strong>{project.tag}</strong> {project.label}</>
                }
                return <WarningOutlined />
            }
        },
        {
            title: t('reports.tasks.title'),
            dataIndex: 'taskId',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const timeReport = record as TimeReport;
                const task = tasks.find(task => task.id === timeReport.taskId);
                if(task) {
                    return task.name
                }
                return <WarningOutlined />
            }
        },
        {
            title: t('reports.note.title'),
            dataIndex: 'note',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                return text;
            }
        },
        {
            title: t('reports.displayName.title'),
            dataIndex: 'displayName',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const timeReport = record as TimeReport;
                const companyUser = companyUsers.find(user => user.id === timeReport.forUser);
                if(companyUser) {
                    return companyUser.displayName;
                }
                return <WarningOutlined />
            }
        },
        {
            title: t('reports.totalTime.title'),
            dataIndex: 'totalTime',
            render: (text: number) => {
                return text.toFixed(2)
            }
        }
    ];
    
    if(errorReports) {
        return (
            <Alert
                message="Error"
                description={errorReports.message}
                type="error"
                showIcon
            />
        )
    }
    
    return (
        <>  
            {reportSummary &&
                <Statistic title={t('reports.summaryHours')} value={reportSummary.totalTime} />
            }
            <br />
            {tableData && tableData.length > 0 &&
                <ReportDateChart companyKey={companyKey} reports={reports} />
            }
            <br />
            <Table 
                dataSource={tableData}
                expandable={{
                    expandRowByClick: true
                }}
                columns={columns}
                size="small"
                loading={loadingReports || loadingTasks || loadingProjects || loadingClients || loadingCompanyUsers}
                rowKey='id'
                pagination={false}
            />
        </>
    )
};

export default ReportsList;