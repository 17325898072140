import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";
import { Project } from '../models/Project';
import { useCompanyUser } from './useCompanyUser';
import moment from 'moment';

export const getBudgetInfo = (project: Project) => {
    var totalSpent = 0;
    if(project.totalHours !== undefined && project.totalMinutes !== undefined) {
        var workedTotalTime = moment.duration({
        minutes: project.totalMinutes,
        hours: project.totalHours
        });
        totalSpent = workedTotalTime.asHours();
    }
    var remainingPercent = (totalSpent / project.budget) * 100;
    return {
        totalSpent: totalSpent,
        hasBudget: project.budget > 0,
        budgetIsOver: totalSpent > project.budget,
        remaining: project.budget - totalSpent,
        remainingPercent: remainingPercent > 100 ? 100 - remainingPercent : remainingPercent
    }
}

export const useProjects = (companyId: string) => {
    const [,, user] = useCompanyUser(companyId);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Project[]>([]);
    const [saveLoading, setSaveLoading] = useState(false);

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .doc(idToUpdate)
            .update(values)
        setSaveLoading(false);
    }

    useEffect(() => {
        if(!companyId || !user) {
            return;
        }
        
        const onSnapshot = (snapshot: any) => {
            setData(
                snapshot.docs.map((doc: any) => ({
                    id: doc.id,
                    ...doc.data(),
                    ...getBudgetInfo(doc.data())
                }))
            );
            setLoading(false);
        };

        if(user.isAdmin) {
            const unsubscribe = firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .orderBy("tag", "desc")
            .onSnapshot(onSnapshot, (error: any) => {
                setError(error)
            },);
    
            return unsubscribe;
        } else {
            const unsubscribe = firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .where("projectManagers", "array-contains", user.id)
            .orderBy("tag", "desc")
            .onSnapshot(onSnapshot, (error: any) => {
                setError(error)
            },);

            return unsubscribe;
        }

        
    }, [companyId, user]);

    return [error, loading, data, updateData, saveLoading] as const;
};