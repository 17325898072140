import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";
import { CompanyUser } from '../models/CompanyUser';

export const useCompanyUsers = (companyId: string) => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<CompanyUser[]>([]);
    
    useEffect(() => {
        if(!companyId) {
            setLoading(false);
            return;
        }

        const onSnapshot = (snapshot: any) => {
            setData(
                snapshot.docs.map((doc: any) => ({
                    ...doc.data() as CompanyUser,
                    id: doc.id
                }))
            );
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('companyUsers')
        .onSnapshot(onSnapshot, (error: any) => {
            setError(error)
        });

        return unsubscribe;

        
    }, [companyId]);

    return [error, loading, data] as const;
};