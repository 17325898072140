import React, {FC} from 'react';
import { useInvite } from '../../hooks/useInvite';
import PageLoading from '../../common/PageLoading';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const AcceptInvite: FC = () => {
    let { invite_id } = useParams();
    const [error, loading, data, acceptInvite] = useInvite(invite_id);
    const { t } = useTranslation();
    const navigate = useNavigate();

    if(loading) {
        return <PageLoading />
    }
    
    if(error) {
        return <Alert
            message="Error"
            description={t(error.message)}
            type="error"
            showIcon
        />
    }

    const onAcceptInvite = async() => {
        console.log('onAcceptInvite');
        console.log(data);
        
        await acceptInvite(data);
        navigate('/companies');
    }
    
    return (
        <Button type="primary" size="large" onClick={onAcceptInvite}>{t('invite.accept')}</Button>
    )
};
export default AcceptInvite;