import { Tabs } from 'antd';
import React, {FC} from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CompanySettings from './components/CompanySettings';
import SubscriptionSettings from './components/SubscriptionSettings';

const { TabPane } = Tabs;

const Settings: FC = () => {
    let { company_key, type } = useParams();
    const { t } = useTranslation();

    return (
        <Tabs activeKey={type}>
            <TabPane tab={<NavLink to="../company">{t('settings.company.title')}</NavLink>} key="company">
                <CompanySettings companyKey={company_key} />
            </TabPane>
            <TabPane tab={<NavLink to="../subscription">{t('settings.subscription.title')}</NavLink>} key="subscription">
                <SubscriptionSettings companyKey={company_key} />
            </TabPane>
        </Tabs>
    )
};
export default Settings;