import React, {FC, useEffect, useState} from 'react';
import { Button, DatePicker, Form, Select, Spin } from 'antd';
import moment from 'moment';
import { useProjects } from '../../../../hooks/useProjects';
import { useTeam } from '../../../../hooks/useTeam';
import { useSearchParams } from 'react-router-dom';
import { useTasks } from '../../../../hooks/useTasks';
import { useClients } from '../../../../hooks/useClients';
import { useTranslation } from 'react-i18next';
import { useExpenseCategories } from '../../../../hooks/useExpenseCategories';
import { Task } from '../../../../models/Task';
import { Project } from '../../../../models/Project';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface ReportsFilterProps {
    companyKey: string,
    type: string
}

const ReportsFilter: FC<ReportsFilterProps> = ({companyKey, type}) => {
    const [, loadingClients, clients] = useClients(companyKey);
    const [, loadingProjects, projects] = useProjects(companyKey);
    const [, loadingTeam, team] = useTeam(companyKey);
    const [, loadingTasks, tasks] = useTasks(companyKey);
    const [, loadingCategories, categories] = useExpenseCategories(companyKey);
    let [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const [activeProjects, setActiveProjects] = useState<Project[]>([]);
    const [otherProjects, setOtherProjects] = useState<Project[]>([]);

    useEffect(() => {
        setActiveProjects(projects.filter(project => project.status === "active"));
        setOtherProjects(projects.filter(project => project.status !== "active"));
    }, [projects])

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };
    const tailLayout = {
        wrapperCol: { offset: 4, span: 8 },
    };

    const dateFormat = 'YYYY-MM-DD';

    const onFinish = (values: any) => {
        setSearchParams({
            ...values,
            timeframe: values['timeframe'] ? values['timeframe'].map((time: any) => moment(time).format(dateFormat)) : undefined
        });
        window.location.reload();
        // TODO: Remove this workaround for useReports filter not updating params
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    if(loadingClients || loadingProjects || loadingTeam || loadingTasks || loadingCategories) {
        return <Spin />
    }
    
    return (
        <Form
            {...layout}
            name="ReportsFilterForm"
            initialValues={{ 
                timeframe: searchParams.has('timeframe') ? (searchParams.getAll('timeframe').map((time: any) => time === 'undefined' || time === 'all_time' ? undefined : moment(time))) : [moment().startOf('week'), moment().endOf('week')],
                clients: searchParams.getAll('clients'),
                projects: searchParams.getAll('projects'),
                tasks: searchParams.getAll('tasks'),
                categories: searchParams.getAll('categories'),
                team: searchParams.getAll('team')
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >

            <Form.Item
                label={t('reports.timeframe.title')}
                name="timeframe"
                rules={[{ required: true, message: 'Välj datum' }]}
            >
                <RangePicker
                    ranges={{
                        'Förra månaden': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                        'Förra veckan': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                        'Denna vecka': [moment().startOf('week'), moment().endOf('week')],
                        'Denna månad': [moment().startOf('month'), moment().endOf('month')],
                        'Idag': [moment(), moment()]
                    }}
                />
            </Form.Item>

            <Form.Item
                label={t('reports.clients.title')}
                name="clients"
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('reports.clients.placeholder')}
                >
                    {clients.map(client => 
                        <Option key={client.id} value={client.id}>{client.name}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                label={t('reports.projects.title')}
                name="projects"
            >
                <Select
                    mode="multiple"
                    allowClear
                    filterOption={(input, option) => 
                        option && option.props && option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder={t('reports.projects.placeholder')}
                >
                    <>
                        <Option key="active" value="active" disabled>{t('reports.projects.active')}</Option>
                        {activeProjects.map(project => 
                            <Option key={project.tag} value={project.id}>{project.tag} - {project.label}</Option>
                        )}
                        <Option key="other" value="other" disabled>{t('reports.projects.other')}</Option>
                        {otherProjects.map(project => 
                            <Option key={project.tag} value={project.id}>{project.tag} - {project.label}</Option>
                        )}
                    </>
                </Select>
            </Form.Item>
            
            <Form.Item
                label={t('reports.tasks.title')}
                name="tasks"
                hidden={type !== 'timeReports'}
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('reports.tasks.placeholder')}
                >
                    {tasks.map(task => 
                        <Option key={task.id} value={task.id}>{task.name}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                label={t('reports.categories.title')}
                name="categories"
                hidden={type !== 'expenses'}
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('reports.categories.placeholder')}
                >
                    {categories.map(category => 
                        <Option key={category.id} value={category.id}>{category.name}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                label={t('reports.team.title')}
                name="team"
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('reports.team.placeholder')}
                >
                    {team.map(user => 
                        <Option key={user.id} value={user.id}>{user.displayName}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                {t('reports.show')}
                </Button>
            </Form.Item>
        </Form>
    )
};

export default ReportsFilter;