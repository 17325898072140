import {useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import { Project } from '../models/Project';
import { getBudgetInfo } from './useProjects';

export const useProject = (companyId: string, projectId?: string) => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [data, setData] = useState<Project>();

    const createData = async (values: any) => {
        setSaveLoading(true);
        const docRef = await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .add(values);
        setSaveLoading(false);
        return docRef;
    }

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .doc(idToUpdate)
            .update(values)
        setSaveLoading(false);
    }

    useEffect(() => {
        if(!companyId || !projectId) {
            setLoading(false);
            return;
        }

        const onSnapshot = (snapshot: any) => {
            setData({
                ...snapshot.data(),
                id: snapshot.id,
                ...getBudgetInfo(snapshot.data())
            });
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('projects')
        .doc(projectId)
        .onSnapshot(onSnapshot, (error: any) => {
            setError(error)
        });
    
        return unsubscribe;

    }, [companyId, projectId]);

    return [error, loading, data, createData, updateData, saveLoading] as const;
}