import { Tabs } from 'antd';
import React, {FC} from 'react';
import { NavLink, useParams } from 'react-router-dom';
import DetailedTime from './components/DetailedTime';
import { useTranslation } from 'react-i18next';
import Expenses from './components/Expenses';

const { TabPane } = Tabs;

const Reports: FC = () => {
    let { company_key, type } = useParams();
    const { t } = useTranslation();

    return (
        <Tabs activeKey={type}>
            <TabPane tab={<NavLink to="../detailedTime">{t('reports.detailedTime')}</NavLink>} key="detailedTime">
                <DetailedTime companyKey={company_key}/>
            </TabPane>
            <TabPane tab={<NavLink to="../expenses">{t('reports.expenses')}</NavLink>} key="expenses">
                <Expenses companyKey={company_key}/>
            </TabPane>
        </Tabs>
    )
};
export default Reports;