import React, {FC, useState, useEffect} from 'react';
import { Button, Divider, PageHeader, Row, Col, Statistic, Space, Tabs } from 'antd';
import { useProject } from '../../../../hooks/useProject';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoading from '../../../../common/PageLoading';
import ProjectEdit from './ProjectEdit';
import { useTranslation } from 'react-i18next';
import { useReports, useReportSummary } from '../../../../hooks/useReports';
import BudgetProgressBar from '../components/BudgetProgressBar';
import ProjectTaskList from './components/ProjectTaskList';
import ProjectUserList from './components/ProjectUserList';
import ProjectProgressChart from './components/ProjectProgressChart';
import { TimeReport } from '../../../../models/TimeReport';

const { TabPane } = Tabs;

const Project: FC = () => {
    let { company_key, project_id } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [, loadingProject, project, createData, updateData, saveLoading] = useProject(company_key, project_id);
    const [, loadingReports, reports] = useReports<TimeReport>(company_key, "timeReports", {projects: [project_id]});
    const timeReportSummary = useReportSummary<TimeReport>(reports);
    const navigate = useNavigate();
    const { t } = useTranslation();

    

    if(loadingProject || loadingReports) {
        return <PageLoading />
    }

    if(!project) {
        return <p>{t('projects.notFound')}</p>
    }

    return (
        <>
            <PageHeader
                onBack={() => navigate('../')}
                title={<><strong>{project.tag}</strong> {project.label}</>}
                extra={[
                    <Button key="1" type="primary" onClick={() => setIsModalVisible(true)}>
                        {t('common.edit')}
                    </Button>,
                ]}
            />
            
            <Divider />
            <ProjectProgressChart companyKey={company_key} item={project} reports={reports}/>
            <Divider />
            <Row gutter={16}>
                <Col span={4}>
                    <Statistic title={t('reports.summaryHours')} value={timeReportSummary?.totalTime} />
                </Col>
                <Col span={4}>
                    <Statistic 
                        title={t('projects.budget.title') + (project.hasBudget ? '(' + Math.floor(project.remainingPercent) + '%)' : '')} 
                        value={project.hasBudget ? project.remaining.toFixed(2) : t('projects.budget.none') as string} 
                        valueStyle={{color: project.hasBudget && project.budgetIsOver ? 'red' : 'inherit'}}
                    />
                    {project.hasBudget &&
                    <>
                        <BudgetProgressBar project={project}/>
                        <Space align='center'>
                        <strong>{t('projects.budget.title')}</strong>
                        {project.budget}
                        </Space>
                    </>
                    }
                </Col>
                <Col span={4}>
                    <Statistic title={t('projects.internalCost') + ' (SEK)'} value={timeReportSummary?.internalCost} />
                </Col>
            </Row>
            <Divider />
            <Tabs centered>
                <TabPane tab={t('reports.tasks.title')} key="1">
                    <ProjectTaskList companyKey={company_key} item={project} reports={reports}/>
                </TabPane>
                <TabPane tab={t('reports.team.title')} key="2">
                    <ProjectUserList companyKey={company_key} item={project} reports={reports}/>
                </TabPane>
            </Tabs>
            <ProjectEdit visible={isModalVisible} companyKey={company_key} item={project} onClose={() => {setIsModalVisible(false)}} createData={createData} updateData={updateData} loading={saveLoading}/>
        </>
    );
};
export default Project;