import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";
import { Client } from '../models/Client';

export const useClients = (companyId: string) => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Client[]>([]);
    const [saveLoading, setSaveLoading] = useState(false);

    const createData = async (values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('clients')
            .add(values)
        setSaveLoading(false);
    }

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('clients')
            .doc(idToUpdate)
            .update(values)
        setSaveLoading(false);
    }

    useEffect(() => {
        const onSnapshot = (snapshot: any) => {
            setData(
                snapshot.docs.map((doc: any) => ({
                    ...doc.data(),
                    id: doc.id
                }))
            );
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('clients')
        .onSnapshot(onSnapshot, (error: any) => {
            setError(error)
        },);

        return unsubscribe;

        
    }, [companyId]);

    return [error, loading, data, createData, updateData, saveLoading] as const;
};