import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";
import { ExpenseCategory } from '../models/ExpenseCategory';

export const useExpenseCategories = (companyId: string) => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ExpenseCategory[]>([]);
    const [saveLoading, setSaveLoading] = useState(false);

    const createData = async (values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('expenseCategories')
            .add(values)
        setSaveLoading(false);
    }

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('expenseCategories')
            .doc(idToUpdate)
            .update(values)
        setSaveLoading(false);
    }

    useEffect(() => {
        const onSnapshot = (snapshot: any) => {
            setData(
                snapshot.docs.map((doc: any) => ({
                    ...doc.data() as ExpenseCategory,
                    id: doc.id
                }))
            );
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('expenseCategories')
        .orderBy('order')
        .onSnapshot(onSnapshot, (error: any) => {
            setError(error)
        });

        return unsubscribe;

        
    }, [companyId]);

    return [error, loading, data, createData, updateData, saveLoading] as const;
};