import {useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import { CompanyUser } from '../models/CompanyUser';
import { useAuthState } from 'react-firebase-hooks/auth';

export const useCompanyUser = (companyId: string, userId?: string) => {
    const [user] = useAuthState(firebase.auth());
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [data, setData] = useState<CompanyUser>();

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('companyUsers')
            .doc(idToUpdate)
            .update(values)
        setSaveLoading(false);
    }

    useEffect(() => {
        if(!companyId || !user) {
            setLoading(false);
            return;
        }

        const onSnapshot = (snapshot: any) => {
            setData({
                ...snapshot.data(),
                id: snapshot.id
            });
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('companyUsers')
        .doc(userId ? userId : user.uid)
        .onSnapshot(onSnapshot, (error: any) => {
            setLoading(false);
            setError(error);
        });
    
        return unsubscribe;

    }, [companyId, user, userId]);

    return [error, loading, data, updateData, saveLoading] as const;
}