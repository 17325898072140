import React, {FC, useState} from 'react';
import { Alert, Avatar, Button, List } from 'antd';
import { Link } from 'react-router-dom';
import { useCompanies } from '../../hooks/useCompanies';
import { useTranslation } from 'react-i18next';
import CompanyEdit from './components/CompanyEdit';

const Companies: FC = () => {
    const [error, loading, companies, createData, updateData, saveLoading] = useCompanies();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();

    if(error) {
        return <Alert type="error" message={error.message} banner />
    }
    
    return (
        <>
            <List
                bordered
                dataSource={companies}
                loading={loading}
                renderItem={(item: any) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={item.image} />}
                            title={<Link to={`../console/${item.id}`}>{item.name}</Link>}
                        />
                    </List.Item>
                )}
            />
            <br />
            <Button size="large"  type="primary" onClick={() => setIsModalVisible(true)}>{t('common.add')}</Button>
            <CompanyEdit visible={isModalVisible} onClose={() => {setIsModalVisible(false)}} createData={createData} updateData={updateData} loading={saveLoading}/>
        </>
    )
};

export default Companies;