import React, {FC, useEffect, useState} from 'react';
import { Alert, Button, Form, Input, InputNumber, notification, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '../../../../hooks/useSubscription';
import { useCompany } from '../../../../hooks/useCompany';
import { useCompanyUser } from '../../../../hooks/useCompanyUser';
import moment from 'moment';
import * as firebase from "firebase/app";
import { useAuthState } from 'react-firebase-hooks/auth';

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 8 },
};

interface SubscriptionSettingsProps {
    companyKey: string
}

const priceId: string = 'price_1IafaxCI4S51cMPPtiouOssm'; // 'price_1ITlYsCI4S51cMPP2RN2df5O';
const defaultTaxRate: string = 'txr_1IUAkACI4S51cMPP4DpvZPhj'; // 'txr_1CLEkHCI4S51cMPPpFmlJ4rQ';

const SubscriptionSettings: FC<SubscriptionSettingsProps> = ({companyKey}) => {
    const [user, loadingUser] = useAuthState(firebase.auth());
    const [, loadingCompanyUser, companyUser] = useCompanyUser(companyKey);
    const [, loadingCompany, dataCompany] = useCompany(companyKey);
    const [error, loading, data, createData, updateData, deleteData, saveLoading] = useSubscription(companyKey);
    const [loadingPortal, setLoadingPortal] = useState(false);
    const { t } = useTranslation();
    const costPerSeat = 129;
    const [form] = Form.useForm();

    useEffect(() => {
        if(!data && form) {
            form.resetFields();
        }
    },[data, form]);

    if(error) {
        return (
            <Alert
                message="Error"
                description={error.message}
                type="error"
                showIcon
            />
        )
    }

    if(loading || loadingUser || loadingCompanyUser || loadingCompany) {
        return <Spin />
    }

    const onFinish = () => {
        form.validateFields()
        .then(async values => {

            if(!data) {
                var trialEndMoment = moment();
                trialEndMoment.add(30, 'days');
                
                await createData({
                    userId: user?.uid,
                    email: companyUser?.email || user?.email,
                    name: dataCompany?.name,
                    customerId: dataCompany && dataCompany.stripeCustomer ? dataCompany.stripeCustomer.customer_id: null,
                    quantity: values.quantity,
                    priceId: priceId,
                    default_tax_rates: [defaultTaxRate],
                    trialEnd: trialEndMoment.unix(),
                    status: 'waiting'
                });
            } else {
                await updateData(data.id, {quantity: values.quantity});
            }
        })
    };

    const onValuesChange = (values: any) => {
        if(values.quantity) {
            form.setFieldsValue({
                totalCost: values.quantity*costPerSeat
            });
        }
    }

    const onManageSubscription = () => {
        setLoadingPortal(true);
        var createCustomerPortalSession = firebase.functions().httpsCallable('stripe-createCustomerPortalSession');
        createCustomerPortalSession({ 
            customerId: dataCompany?.stripeCustomer?.customer_id,
            companyId: dataCompany?.id
        })
        .then((response) => {
            setLoadingPortal(false);
            window.location.replace(response.data);
        })
        .catch((error) => {
            notification.error({
                message: error.message,
                description: error.details
            });
            setLoadingPortal(false);
        });
        
    }

    return (
        <Space direction="vertical" style={{width: '100%'}}>
            <Form
            {...layout}
            form={form}
            name="basic"
            initialValues={{ quantity: data ? data.quantity: 10, costPerSeat, totalCost: data ? data.quantity * costPerSeat : costPerSeat * 10 }}
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            >
                <Form.Item
                    label={t('settings.subscription.quantity')}
                    extra={t('settings.subscription.extra')}
                    name="quantity"
                    rules={[{ required: true }]}
                >
                    <InputNumber disabled={data != null} min={1}/>
                </Form.Item>

                <Form.Item 
                    label={t('settings.subscription.costPerSeat')}
                    name="costPerSeat"
                >
                    <Input disabled type="number" addonBefore="SEK" addonAfter={t('settings.subscription.perMonth')}/>
                </Form.Item>

                <Form.Item
                    label={t('settings.subscription.totalCost')}
                    name="totalCost"
                >
                    <Input disabled type="number" addonBefore="SEK" addonAfter={t('settings.subscription.perMonth')}/>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    {data ? (
                        <Button danger onClick={onManageSubscription} disabled={data && (data.status === 'waiting')} type="primary" loading={loadingPortal} >
                        {t('settings.subscription.manage')}
                        </Button>
                    ):(
                        <>
                        <Button disabled={data && (data.status === 'waiting')} type="primary" htmlType="submit" loading={saveLoading}>
                        {t('settings.subscription.trial')}
                        </Button>
                        <br/>
                        <p>{t('settings.subscription.trialExtra')}</p>
                        </>
                    )}
                </Form.Item>
            </Form>

            <Alert
                message={t('settings.subscription.title')}
                description={t('settings.subscription.status.'+data.status)}
                type={data.status == "active" ? "info" : "warning"}
                showIcon
            />

        </Space>
    )
};

export default SubscriptionSettings;