import React, {FC} from 'react';
import { Button, Col, Row, Spin, Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import * as firebase from "firebase/app";
import { useAuthState } from 'react-firebase-hooks/auth';

import BannerImage from '../../../images/banner.jpg';
import appBackgroundContent from '../../../images/app-background-content.jpg';
import storeAndroid from '../../../images/store-android.png';
import storeIos from '../../../images/store-ios.png';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const LandingPage: FC = () => {
    const [user, loading] = useAuthState(firebase.auth());
    return (
        <>
            <div className={css(styles.banner)}>
                <Title>Kom igång direkt</Title>
                <Title type="secondary" level={3}>Rapportera tid och utlägg var du än är</Title>
                {loading ? (
                    <Spin />
                ):(
                    <Link to={user ? "companies" : "signIn"}>
                        <Button type="primary">Kom igång</Button>
                    </Link>
                )}
                
            </div>
            <div className={css(styles.container)}>
                <Row>
                    <Col sm={24} md={12}>
                        <img alt="Tidmer" src={appBackgroundContent} />
                    </Col>
                    <Col sm={24} md={12}>
                        <Title level={2}>Skaffa appen.</Title>
                        <Title type="secondary" level={2}>Byggd för enkelhet.</Title>
                        <Text>
                            Genom vår app kan alla anställda rapportera tid och utlägg överallt. Appen är speciellt utformad för att vara enkel, lätt och smidig att jobba med.
                        </Text>
                        <Row>
                            <Col span={12}>
                                <a href="https://play.google.com/store/apps/details?id=com.bygget" target="_blank" rel="noopener noreferrer">
                                    <img alt="Android" className={css(styles.store)} src={storeAndroid} />
                                </a>
                            </Col>
                            <Col span={12}>
                                <a href="https://itunes.apple.com/se/app/tidmer/id1174888570?mt=8" target="_blank" rel="noopener noreferrer">
                                    <img alt="iOS" className={css(styles.store)} src={storeIos} />
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
};

const styles = StyleSheet.create({
    banner: {
        backgroundImage: `url(${BannerImage})`,
        backgroundSize: 'cover',
        padding: 100
    },
    container: {
        padding: 20
    },
    store: {
        height: 40,
        width: 'auto'
    }
});

export default LandingPage;