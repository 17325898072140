import {useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import { Application } from '../models/Application';

export const useApplicationData = () => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Application>();

    useEffect(() => {
        const onSnapshot = (snapshot: any) => {
            setData(snapshot.data());
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('application')
        .doc('data')
        .onSnapshot(onSnapshot, (error: any) => {
            setLoading(false);
            setError(error);
        });
    
        return unsubscribe;

    }, []);

    return [error, loading, data] as const;
}