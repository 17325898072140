import React, {FC} from 'react';
import { Button, Col, Row, Spin, Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';

const { Title, Text } = Typography;

const PrivacyPage: FC = () => {
    return (
        <div>
            <h1>Tidmer Privacy Policy</h1>
            <p>We’re committed to protecting and respecting your privacy.</p>
            <hr/>
            <p>This policy explains when and why we collect personal information about you, how we use it, the conditions under which we may disclose it to others, how we keep it safe and secure and your rights and choices in relation to your information.</p>

            <p>Any questions regarding this policy and our privacy practices should be sent by email to info@tidmer.com</p>

            <h2>Who are we?</h2>
            <hr/>
            <p>Tidmer is handled by Ekorn Event AB, a small business aiming to help companies world-wide.</p>

            <p>In this policy ‘Tidmer’, ’Ekorn Event AB’ , ‘we’, ‘us’ or ‘our’ means:</p>

            <p>Ekorn Event AB, a registered company with office at Sjöängsvägen 23B, 14264 Trångsund, Sweden.</p>

            <h2>How do we collect information from you?</h2>
            <hr/>
            <p>We obtain information about you in the following ways:</p>

            <h3>Information you give us when you register an account</h3>

            <p>For example, we may obtain information about you when you take part in one of our events, register an account or when you register to receive one of our newsletters.</p>

            <h3>Information you give us indirectly</h3>

            <p>Your information may be shared with us by third parties, which might include:</p>

            <p>independent event organisers and subcontractors acting on our behalf who provide us with technical, payment or delivery services, our business partners, advertising networks analytics providers and search information providers.
            You should check any privacy policy provided to you where you give your data to a third party.</p>

            <h3>When you visit this website</h3>

            <p>We, like many companies, automatically collect the following information:</p>

            <p>technical information, including the type of device you’re using, the IP address, browser and operating system being used to connect your computer to the internet. This information may be used to improve the services we offer.
            information about your visit to this website, for example we collect information about pages you visit and how you navigate the website, i.e. length of visits to certain pages, products and services you viewed and searched for, referral sources (e.g. how you arrived at our website).
            We collect and use your personal information by using cookies on our website – more information on cookies can be found under the ‘Use of Cookies’ section below.</p>

            <h3>Social Media</h3>

            <p>When you interact with us on social media platforms such as Facebook and Twitter we may obtain information about you (for example, when you publicly tag us in an event photo). The information we receive will depend on the privacy preferences you have set on those types of platforms.</p>

            <h2>What type of information is collected from you?</h2>
            <hr/>
            <p>The personal information we collect, store and use might include:</p>

            <ul>
                <li>your name and contact details (including postal address, email address and telephone number);</li>
                <li>your date of birth;</li>
                <li>information about your activities on our website and about the device used to access it, for instance your IP address and geographical location;</li> 
                <li>your bank or credit card details. If you make a donation online or make a purchase, your card information is not held by us, it is collected by our third party payment processors, who specialise in the secure online capture and processing of credit/debit card transactions;</li>
                <li>any other personal information shared with us.</li>
            </ul>

            <p>Where appropriate, we will make why we are collecting this type of information and what it will be used for clear.</p>

            <h2>How and why is your information used?</h2>
            <hr/>
            <p>We may use your information for a number of different purposes, which may include:</p>
            <ul>
                <li>providing you with the services, products or information you asked for.</li>
                <li>processing orders that you have submitted;</li>
                <li>carrying out our obligations under any contracts entered into between you and us;</li>
                <li>keeping a record of your relationship with us;</li>
                <li>conducting analysis and market research so we can understand how we can improve our services, products or information;</li>
                <li>checking for updated contact details against third party sources so we can stay in touch if you move;</li>
                <li>dealing with entries into a competition;</li>
                <li>seeking your views or comments on the services we provide;</li>
                <li>notifying you of changes to our services;</li>
                <li>sending you communications which you have requested and that may be of interest to you. These may include information about time reporting and related activities;</li>
            </ul>

            <h2>How long is your information kept for?</h2>
            <hr/>
            <p>We keep your information for no longer than is necessary for the purposes it was collected for, The length of time we retain your personal information for is determined by operational and legal considerations. For example, we are legally required to hold some types of information to fulfil our statutory and regulatory obligations (e.g. health/safety and tax/accounting purposes).</p>

            <p>We review our retention periods on a regular basis.</p>

            <h2>Who has access to your information?</h2>
            <hr/>
            <p>We do not sell or rent your information to third parties.</p>

            <p>We do not share your information with third parties for marketing purposes.</p>

            <p>However, we may disclose your information to third parties in order to achieve the other purposes set out in this policy. These third parties may include:</p>

            <h3>Third parties working on our behalf</h3>
            <p>We may pass your information to our third party service providers, suppliers, agents, subcontractors and other associated organisations for the purposes of completing tasks and providing services to you on our behalf (for example to process donations and send you mailings). However, when we use these third parties, we disclose only the personal information that is necessary to deliver the services and we have a contract in place that requires them to keep your information secure and prevents them from using it for their own direct marketing purposes. Please be reassured that we will not release your information to third parties for them to use for their own direct marketing purposes, unless you have requested us to do so, or we are required to do so by law, for example, by a court order or for the purposes of prevention of fraud or other crime.</p>

            <h3>Third Party Product Providers we work with</h3>
            <p>Our trusted third party product providers provide a range of quality and reliable products and services designed to meet the needs of our users. When you enquire about or purchase one or more of these products, the relevant third party product provider will use your details to provide you with information and carry out their obligations arising from any contracts you have entered into with them. They will be acting as a joint controller of your information and therefore we advise you to read their Privacy Policy. These third party product providers will share your information with us which we will use in accordance with this policy.</p>

            <p>We may transfer your personal information to a third party as part of a sale of some or all of our business and assets to any third party or as part of any business restructuring or reorganisation, or if we’re under a duty to disclose or share your personal data in order to comply with any legal obligation or to enforce or apply our terms of use or to protect the rights, property or safety of our staff, supporters, customers, users of the website or others. However, we will take steps with the aim of ensuring that your privacy rights continue to be protected.</p>

            <h2>Lawful Processing</h2>
            <hr/>
            <p>Data protection law requires us to rely on one or more lawful grounds to process your personal information. We consider the following grounds to be relevant:</p>

            <h3>Specific Consent</h3>

            <p>Where you have provided specific consent to us using your personal information in a certain way, such as to send you email, text and/or telephone marketing.</p>

            <h3>Performance of a contract</h3>

            <p>Where we are entering into a contract with you or performing our obligations under it like when you buy Tidmer named products and services.</p>

            <h3>Legal obligation</h3>

            <p>Where necessary so that we can comply with a legal or regulatory obligation to which we are subject, for example where we are ordered by a court or regulatory authority like the Charity Commission or Fundraising Regulator.</p>

            <h3>Vital interests</h3>

            <p>Where it is necessary to protect life or health (for example in the case of medical emergency suffered by an individual at one of our events) or a safeguarding issue which requires us to share you information with the emergency services.</p>

            <h3>Legitimate interests</h3>

            <p>Where it is reasonably necessary to achieve our or others’ legitimate interests (as long as what the information is used for is fair and does not duly impact your rights).</p>

            <p>We consider our legitimate interests to be running Tidmer in pursuit of our aims and ideals. For example to:</p>

            <ul>
            <li>send postal communications which we think will be of interest to you;</li>
            <li>conduct research to better understand who our visitors are to better target our campaigns;</li>
            <li>monitor who we deal with to protect the service against fraud, money laundering and other risks;</li>
            <li>enhance, modify, personalise or otherwise improve our services /communications for the benefit of our customers;</li>
            <li>understand better how people interact with our website.</li>
            </ul>

            <p>When we legitimately process your personal information in this way, we consider and balance any potential impact on you (both positive and negative), and your rights under data protection laws. We will not use your personal information where our interests are overridden by the impact on you, for example, where use would be excessively intrusive (unless, for instance, we are otherwise required or permitted to by law).</p>

            <h2>Marketing Communications</h2>
            <hr/>
            <p>We may use your contact details to provide you with information about the vital work we do for time reporting and opportunities to support us, as well as the services you can buy, if we think it may be of interest to you.</p>

            <h3>Email/text/Phone</h3>

            <p>We will only send you marketing communications by email, text and telephone if you have explicitly provided your prior consent. You may opt out of our marketing communications at any time by clicking the unsubscribe link at the end of our marketing emails.</p>

            <h3>Post</h3>

            <p>We may send you marketing communications by post unless you have told us that you would prefer not to hear from us.</p>

            <h2>Your choices</h2>
            <hr/>
            <p>You have a choice about whether or not you wish to receive information from us. If you do not want to receive direct marketing communications from us about the vital work we do for time reporting and the exciting services you can buy, then you can select your choices by ticking the relevant boxes situated on the form used to collect your information.</p>

            <p>We’re committed to putting you in control of your data so you’re free to change your marketing preferences (including to tell us that you don’t want to be contacted for marketing purposes) at any time by contacting us by email: info@tidmer.com</p>

            <p>We will not use your personal information for marketing purposes if you have indicated that you do not wish to be contacted and will retain your details on a suppression list to help ensure that we do not continue to contact you. However, we may still need to contact you for administrative purposes like where we are processing a donation or thanking you for your participation in an event.</p>

            <h2>Building Profiles</h2>
            <hr/>
            <p>We may analyse your personal information to create a profile of your interests and preferences so that we can tailor and target our communications in a way that is timely and relevant to you. We may make use of additional information about you when it is available from external sources to help us do this effectively. This allows us to be more focused, efficient and cost effective with our resources and also reduces the risk of someone receiving information they may find inappropriate or irrelevant.</p>

            <p>We may also use your personal information to detect and reduce fraud and credit risk.</p>

            <h2>Your Rights</h2>
            <hr/>
            <p>Under EU data protection law, you have certain rights over the personal information that we hold about you. Here is a summary of the rights that we think apply:</p>

            <h3>Right of access</h3>

            <p>You have a right to request access to the personal data that we hold about you. You also have the right to request a copy of the information we hold about you, and we will provide you with this unless legal exceptions apply.</p>

            <p>If you want to access your information, please send a description of the information you want to see and proof of your identity by post to the address provided below.</p>

            <h3>Right to have your inaccurate personal information corrected</h3>

            <p>You have the right to have inaccurate or incomplete information we hold about you corrected. The accuracy of your information is important to us so we’re working on ways to make it easier for you to review and correct the information that we hold about you. In the meantime, if you change email address, or if you believe any of the other information we hold is inaccurate or out of date, please contact us via email or post (see below).</p>

            <h3>Right to restrict use</h3>

            <p>You have a right to ask us to restrict the processing of some or all of your personal information if there is a disagreement about its accuracy or we’re not lawfully allowed to use it.</p>

            <h3>Right of erasure</h3>

            <p>You may ask us to delete some or all of your personal information and in certain cases, and subject to certain exceptions; we will do so as far as we are required to. In many cases, we will anonymise that information, rather than delete it.</p>

            <h3>Right for your personal information to be portable</h3>

            <p>If we are processing your personal information (1) based on your consent, or in order to enter into or carry out a contract with you, and (2) the processing is being done by automated means, you may ask us to provide it to you or another service provider in a machine-readable format.</p>

            <h3>Right to object</h3>

            <p>You have the right to object to processing where we using your personal information (1) based on legitimate interests, (2) for direct marketing or (3) for statistical/research purposes.</p>

            <p>If you want to exercise any of the above rights, please email us at info@tidmer.com We may be required to ask for further information and/or evidence of identity. We will endeavour to respond fully to all requests within one month of receipt of your request, however if we are unable to do so we will contact you with reasons for the delay.</p>

            <p>Please note that exceptions apply to a number of these rights, and not all rights will be applicable in all circumstances.</p>

            <h2>Keeping your information safe</h2>
            <hr/>
            <p>When you give us personal information, we take steps to ensure that appropriate technical and organisational controls are in place to protect it.</p>

            <p>Any sensitive information (such as credit or debit card details) is encrypted and protected with the following software 128 Bit encryption on SSL. When you are on a secure page, a lock icon will appear on the bottom of web browsers such as Microsoft Internet Explorer.</p>

            <p>Non-sensitive details (your email address etc.) are transmitted normally over the Internet, and this can never be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, we cannot guarantee the security of any information you transmit to us, and you do so at your own risk. Once we receive your information, we make our best effort to ensure its security on our systems. Where we have given (or where you have chosen) a password which enables you to access certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>

            <h2>Use of 'cookies'</h2>
            <hr/>
            <p>Like many other websites, this website uses cookies. 'Cookies' are small pieces of information sent by an organisation to your computer and stored on your hard drive to allow that website to recognise you when you visit. For example, we use cookies to store your country preference. This helps us to deliver a better more personalised service when you browse our website and improve our services.</p>

            <p>It is possible to switch off cookies by setting your browser preferences. Turning cookies of may result in a loss of functionality when using our website.</p>

            <h2>Links to other websites</h2>
            <hr/>
            <p>Our website may contain links to other websites run by other organisations. This policy applies only to our website‚ so we encourage you to read the privacy statements on the other websites you visit. We cannot be responsible for the privacy policies and practices of other websites even if you access them using links from our website.</p>

            <p>In addition, if you linked to our website from a third party site, we cannot be responsible for the privacy policies and practices of the owners and operators of that third party site and recommend that you check the privacy policy of that third party site.</p>

            <h2>16 or Under</h2>
            <hr/>
            <p>We are concerned to protect the privacy of children aged 16 or under. If you are aged 16 or under‚ please get your parent/guardian's permission beforehand whenever you provide us with personal information.</p>

            <h2>Vulnerable circumstances</h2>
            <hr/>
            <p>We are committed to protecting vulnerable users, customers and workers and appreciate that additional care may be needed when we use their personal information. In recognition of this, we observe good practice guidelines in our interactions with vulnerable people.</p>

            <h2>Transferring your information outside of Europe</h2>
            <hr/>
            <p>As part of the services offered to you through this website, the information which you provide to us may be transferred to countries outside the European Economic Area (“EEA”). By way of example, this may happen if any of our servers are from time to time located in a country outside of the EEA. You should be aware that these countries may not have similar data protection laws to Sweden. By submitting your personal data, you’re agreeing to this transfer, storing or processing. If we transfer your information outside of the EEA in this way, we will take steps to ensure that appropriate security measures are taken with the aim of ensuring that your privacy rights continue to be protected as outlined in this policy.</p>

            <p>If you use our services while you are outside the EEA, your information may be transferred outside the EEA in order to provide you with those services. We undertake regular reviews of who has access to information that we hold to ensure that your info is only accessible by appropriately trained staff, volunteers and contractors.</p>

            <h2>Changes to this policy</h2>
            <hr/>
            <p>Any changes we may make to this policy in the future will be posted on this website so please check this page occasionally to ensure that you’re happy with any changes. If we make any significant changes we’ll make this clear on this website.</p>

            <h2>Review of this Policy</h2>
            <hr/>
            <p>We keep this policy under regular review. This policy was last updated in June 2021.</p>
        </div>
    )
};

const styles = StyleSheet.create({
    container: {
        padding: 20
    }
});

export default PrivacyPage;