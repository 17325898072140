import React, {FC, useState} from 'react';
import { Button, Select, Space } from 'antd';
import ProjectEdit from '../Project/ProjectEdit';
import { useProject } from '../../../../hooks/useProject';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface ProjectFilterProps {
    companyKey: string,
    defaultValue: string,
    onChange: (value: string) => void
}

const ProjectFilter: FC<ProjectFilterProps> = ({companyKey, defaultValue, onChange}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [,,, createData, updateData, saveLoading] = useProject(companyKey);
    const { t } = useTranslation();

    return (
        <Space>
            <Select defaultValue={defaultValue} size="large" onChange={onChange}>
                <Option value="budget">{t('projects.filter.budget')}</Option>
                <Option value="nonBudget">{t('projects.filter.nonBudget')}</Option>
                <Option value="active">{t('projects.filter.active')}</Option>
                <Option value="closed">{t('projects.filter.closed')}</Option>
            </Select>
            <Button type="primary" size="large" onClick={() => setIsModalVisible(true)}>{t('common.add')}</Button>
            <ProjectEdit visible={isModalVisible} companyKey={companyKey} onClose={() => {setIsModalVisible(false)}} createData={createData} updateData={updateData} loading={saveLoading}/>
        </Space>
    )
};

export default ProjectFilter;