import { DatePicker, Form, Input, Modal, Select, Spin, TimePicker } from 'antd';
import React, {FC, useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import { useTranslation } from 'react-i18next';
import { useClients } from '../../../../hooks/useClients';
import { useProjects } from '../../../../hooks/useProjects';
import { useTasks } from '../../../../hooks/useTasks';
import { TimeReport } from '../../../../models/TimeReport';
import moment from 'moment';
import { useAuthState } from 'react-firebase-hooks/auth';

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface TimeEditProps {
    visible: boolean,
    companyKey: string,
    item?: TimeReport | undefined,
    onClose: () => void,
    createData: (values: any) => Promise<void>,
    updateData?: (id: string, values: any) => Promise<void>,
    loading: boolean
}

const TimeEdit: FC<TimeEditProps> = ({visible, companyKey, item, onClose, createData, updateData, loading}) => {
    const [user] = useAuthState(firebase.auth());
    const [, loadingProjects, projects] = useProjects(companyKey);
    const [, loadingClients, clients] = useClients(companyKey);
    const [, loadingTasks, tasks] = useTasks(companyKey);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [loadingProjectTasks, setLoadingProjectTasks] = useState(false);
    const [projectTasks, setProjectTasks] = useState<any[]>([]);
    
    useEffect(() => {
        if(form) {
            form.resetFields();
        }
    },[item, form])

    const onSubmit = async (values: any) => {
        form
        .validateFields()
        .then(async values => {
            values.date = values.date.format(dateFormat);
            values.hours = values.time.hours();
            values.minutes = values.time.minutes();
            values.forUser = values.forUser ? values.forUser : user?.uid;
            delete values.time;

            if(values.note === undefined) {
                delete values.note;
            }
            
            if(item) {
                if(updateData) {
                    await updateData(item.id, values);
                }
            } else {
                await createData(values);
            }
            onClose();
        })
        .catch(info => {
            console.log('Validate Failed:', info);
        });
    };
    
    const handleCancel = () => {
        form.resetFields();
        onClose();
    }

    const onChangeProject = (projectId: string) => {
        setLoadingProjectTasks(true);
        firebase
        .firestore()
        .collection('companies')
        .doc(companyKey)
        .collection('projects')
        .doc(projectId)
        .collection('taskAssignments')
        .get()
        .then((querySnapshot) => {
            var tasksToAdd: any[] = [];
            querySnapshot.forEach((doc) => {
                const foundTask = tasks.find(task => task.id === doc.id);
                tasksToAdd.push(foundTask);
            });
            setProjectTasks(tasksToAdd);
            setLoadingProjectTasks(false);
        });
    }

    return (
        <Modal 
            title={item ? t('common.edit') : t('common.add')} 
            visible={visible} 
            confirmLoading={loading}
            forceRender
            onCancel={handleCancel}
            okButtonProps={{form:'timeReportEdit', htmlType: 'submit'}}
        >
            {loadingClients || loadingProjects || loadingTasks ? <Spin /> : (
            <Form
                {...layout}
                form={form}
                name="timeReportEdit"
                initialValues={{
                    ...item,
                    date: (item && item.date) ? moment(item.date, dateFormat) : undefined,
                    time: item ? moment(`${item.hours}:${item.minutes}`, timeFormat) : undefined,
                }}
                onFinish={onSubmit}
            >
                <Form.Item
                    label={t('timeReports.clientId.title')}
                    name="clientId"
                    rules={[{ required: true, message: t('timeReports.clientId.placeholder') }]}
                >
                    <Select
                        style={{ width: '100%' }}
                        placeholder={t('timeReports.clientId.placeholder')}
                    >
                        {clients.map(client => 
                            <Option key={client.id} value={client.id}>{client.name}</Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item
                    noStyle
                    dependencies={['clientId']}
                >
                    {({ getFieldValue }) => {
                        const clientId = getFieldValue('clientId');
                        const clientProjects = projects.filter(project => project.clientId === clientId && project.status === "active");
                        return (
                            <Form.Item
                                label={t('timeReports.projectId.title')}
                                name="projectId"
                                rules={[{ required: true, message: t('timeReports.projectId.placeholder') }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    disabled={!clientId}
                                    filterOption={(input, option) => 
                                        option && option.props && option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={onChangeProject}
                                >
                                    {clientProjects.map(project => 
                                        <Option key={project.tag} value={project.id}>{project.tag} - {project.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        )
                    }}
                </Form.Item>

                <Form.Item
                    noStyle
                    dependencies={['projectId']}
                >
                    {({ getFieldValue }) => {
                        const projectId = getFieldValue('projectId');
                        return (
                            <Form.Item
                                label={t('timeReports.taskId.title')}
                                name="taskId"
                                rules={[{ required: true, message: t('timeReports.taskId.placeholder') }]}
                            >
                                {loadingProjectTasks ? (
                                    <Spin />
                                ):(
                                <Select
                                    style={{ width: '100%' }}
                                    disabled={!projectId}
                                    filterOption={(input, option) => 
                                        option && option.props && option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {projectTasks.map(task => 
                                        <Option key={task.id} value={task.id}>{task.name}</Option>
                                    )}
                                </Select>
                                )}
                            </Form.Item>
                        )
                    }}
                </Form.Item>


                <Form.Item
                    label={t('timeReports.date.title')}
                    name="date"
                    rules={[{ required: true, message: t('timeReports.date.placeholder') }]}
                >
                    <DatePicker style={{width: '100%'}} format={dateFormat}/>
                </Form.Item>

                <Form.Item
                    label={t('timeReports.time.title')}
                    name="time"
                    rules={[{ required: true, message: t('timeReports.date.placeholder') }]}
                >
                    <TimePicker style={{width: '100%'}} format={timeFormat} showNow={false}/>
                </Form.Item>

                <Form.Item
                    label={t('timeReports.note.title')}
                    name="note"
                >
                    <Input.TextArea placeholder={t('timeReports.note.placeholder')}/>
                </Form.Item>
            </Form>
            )}
        </Modal>
    )
};
export default TimeEdit;