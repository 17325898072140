import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";
import { useAuthState } from 'react-firebase-hooks/auth';

export const useInvite = (inviteId?: string | undefined) => {
    const [user] = useAuthState(firebase.auth());
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [saveLoading, setSaveLoading] = useState(false);

    const acceptInvite = async (values: any) => {
        if(!user || !inviteId) {
            return;
        }

        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('invites')
            .doc(inviteId)
            .update({
                acceptedByUserId: user.uid,
                accepted: true,
                displayName: user.displayName,
                email: user.email
            });
        setSaveLoading(false);
    }

    const resendInvite = async (email: string) => {
        if(!user || !email) {
            return;
        }

        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('invites')
            .where('email','==',email)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.update({sendInvitation: true})
                });
            })
            .catch((error) => {
                setError({message: 'invite.notFound'})
                return false
            });
        setSaveLoading(false);
        return true;
    }

    const deleteInvite = async (email: string) => {
        if(!user || !email) {
            return;
        }

        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('invites')
            .where('email','==',email)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.delete();
                });
            })
            .catch((error) => {
                setError({message: 'invite.notFound'})
                return false
            });
        setSaveLoading(false);
        return true;
    }

    useEffect(() => {
        if(inviteId) {
            const onSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) => {
                if(snapshot.exists) {
                    setData({
                        ...snapshot.data(),
                        id: snapshot.id
                    });
                } else {
                    setError({message: 'invite.notFound'})
                }
                setLoading(false);
            };

            const unsubscribe = firebase
            .firestore()
            .collection('invites')
            .doc(inviteId)
            .onSnapshot(onSnapshot, (error: any) => {
                setLoading(false);
                setError(error);
            });
        
            return unsubscribe;
        } else {
            setLoading(false);
        }
    }, [inviteId]);

    return [error, loading, data, acceptInvite, resendInvite, deleteInvite, saveLoading] as const;
};