import { Divider } from 'antd';
import React, {FC} from 'react';
import ReportsFilter from './ReportsFilter';
import ReportsList from './ReportsList';

interface DetailedTimeProps {
    companyKey: string
}

const DetailedTime: FC<DetailedTimeProps> = ({companyKey}) => {
    return (
        <>
            <ReportsFilter companyKey={companyKey} type="timeReports"/>
            <Divider />
            <ReportsList companyKey={companyKey}/>
        </>
    )
};
export default DetailedTime;