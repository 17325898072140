import React, {FC} from 'react';
import { Outlet } from 'react-router-dom';

const Console: FC = () => {
    
    return (
        <Outlet />
    )
};

export default Console;