import { Divider, Form, Input, Modal, notification, Radio, Switch } from 'antd';
import React, {FC, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyUser } from '../../../../models/CompanyUser';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface CompanyUserEditProps {
    visible: boolean,
    companyKey: string,
    item?: CompanyUser,
    onClose: () => void,
    createData?: (values: any) => Promise<void>,
    updateData?: (id: string, values: any) => Promise<void>,
    loading: boolean
}

const CompanyUserEdit: FC<CompanyUserEditProps> = ({visible, item, onClose, createData, updateData, loading}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
        if(form) {
            form.resetFields();
        }
    },[item, form])
    
    const onSubmit = async () => {
        form
        .validateFields()
        .then(async values => {
            if(item && updateData) {
                delete values.email;
                Object.keys(values).forEach(key => values[key] === undefined ? delete values[key] : {});
                await updateData(item.id, values);
            } else if(createData){
                await createData(values);
            }
            onClose();
        })
        .catch(error => {
            notification.error({
                message: 'Problem',
                description: error.message
            });
        });
    };
    
    const handleCancel = () => {
        onClose();
    }

    return (
        <Modal 
            title={item ? item.displayName : t('common.add')} 
            visible={visible} 
            confirmLoading={loading}
            onCancel={handleCancel}
            okButtonProps={{form:'companyUserEdit', htmlType: 'submit'}}
        >
            <Form
                {...layout}
                form={form}
                name="companyUserEdit"
                initialValues={item ? item : {
                    capacity: 0,
                    costRate: 0,
                    defaultHourlyRate: 0,
                    hasAccessToAllFutureMissions: true,
                    isActive: true,
                    isAdmin: false,
                    isContractor: false
                }}
                onFinish={onSubmit}
            >
                <Form.Item name="isContractor" label={t('team.companyUser.isContractor.title')}>
                    <Radio.Group>
                        <Radio value={false}>{t('team.companyUser.isContractor.employee')}</Radio>
                        <Radio value={true}>{t('team.companyUser.isContractor.contractor')}</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={t('team.companyUser.displayName')}
                    name="displayName"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('team.companyUser.email')}
                    name="email"
                >
                    <Input disabled={item !== undefined}/>
                </Form.Item>

                <Divider />

                <Form.Item
                    label={t('team.companyUser.defaultHourlyRate.title')}
                    name="defaultHourlyRate"
                    extra={t('team.companyUser.defaultHourlyRate.extra')}
                >
                    <Input type="number" addonBefore="SEK" addonAfter={t('team.companyUser.defaultHourlyRate.perHour')} placeholder="0" />
                </Form.Item>

                <Form.Item
                    label={t('team.companyUser.costRate.title')}
                    name="costRate"
                    extra={t('team.companyUser.costRate.extra')}
                >
                    <Input type="number" addonBefore="SEK" addonAfter={t('team.companyUser.costRate.perHour')} placeholder="0" />
                </Form.Item>

                <Form.Item
                    label={t('team.companyUser.capacity.title')}
                    name="capacity"
                    extra={t('team.companyUser.capacity.extra')}
                >
                    <Input type="number" addonBefore="Timmar" addonAfter={t('team.companyUser.capacity.perWeek')} placeholder="40" />
                </Form.Item>

                <Divider />
            
                <Form.Item
                    label={t('team.companyUser.isAdmin')}
                    name="isAdmin"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    label={t('team.companyUser.hasAccessToAllFutureMissions')}
                    name="hasAccessToAllFutureMissions"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    label={t('team.companyUser.isActive')}
                    name="isActive"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

            </Form>
        </Modal>
    )
};
export default CompanyUserEdit;