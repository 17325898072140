import React, {FC, useEffect, useState} from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTasks } from '../../../../hooks/useTasks';
import { TimeReport } from '../../../../models/TimeReport';
import moment from 'moment';
import { Column } from '@ant-design/charts';

interface ReportDateChartProps {
    companyKey: string,
    reports: TimeReport[]
}

interface ReportDateTotalTime {
    date: string,
    value: number
}

const ReportDateChart: FC<ReportDateChartProps> = ({companyKey, reports}) => {
    const [, loadingTasks, defaultTasks] = useTasks(companyKey);
    const [graphData, setGraphData] = useState<any>();
    const { t } = useTranslation();

    useEffect(() => {
        if(reports && defaultTasks) {
            const reportDates: any[] = [];
            reports.forEach(report => {
                if(!reportDates.includes(report.date)) {
                    reportDates.push(report.date)
                }
            });
            const reportDatesValues: any[] = reportDates.map(date => {
                var totalHours: number = 0;
                var totalMinutes: number = 0;
                const taskReports = reports.filter(report => report.date === date);
                taskReports.forEach(taskReport => {
                    totalHours += +taskReport.hours;
                    totalMinutes += +taskReport.minutes;
                });
                var totalTimeMoment = moment.duration(totalHours, 'hours');
                totalTimeMoment.add(totalMinutes, 'minutes');
                return {
                    date: date,
                    value: Math.floor(totalTimeMoment.asHours())
                }
            });
            setGraphData(reportDatesValues);
        }
    },[reports, defaultTasks])

    const config = {
        data: graphData,
        xField: 'date',
        yField: 'value',
        tooltip: {
            fields: ['date', 'value'],
            formatter: (input: any) => {
                return { name: input.date, value: input.value + ' ' + t('reports.totalTime.title').toLowerCase() };
            },
        },
        xAxis: {
            label: {
                formatter: (date: string) => {
                    return moment(date).format('ddd D/M')
                }
            },
        },
        label: {
            position: 'middle',
            layout: [{ type: 'adjust-color' }],
        },
        point: {
            size: 5,
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        }
    };

    if(loadingTasks) {
        return <Spin />
    }

    if(!graphData) {
        return null
    }

    return (
        <Column {...config} />
    )
};

export default ReportDateChart;