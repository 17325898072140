import React, {FC} from 'react';
import { Button, DatePicker, Form, Select, Spin } from 'antd';
import moment from 'moment';
import { useProjects } from '../../../../hooks/useProjects';
import { useTeam } from '../../../../hooks/useTeam';
import { useSearchParams } from 'react-router-dom';
import { useTasks } from '../../../../hooks/useTasks';
import { useClients } from '../../../../hooks/useClients';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface TimeFilterProps {
    companyKey: string
}

const Timeilter: FC<TimeFilterProps> = ({companyKey}) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };
    const tailLayout = {
        wrapperCol: { offset: 4, span: 8 },
    };

    const dateFormat = 'YYYY-MM-DD';

    const onFinish = (values: any) => {
        setSearchParams({
            ...values,
            timeframe: values['timeframe'] ? values['timeframe'].map((time: any) => moment(time).format(dateFormat)) : undefined
        });
        window.location.reload();
        // TODO: Remove this workaround for useReports filter not updating params
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            {...layout}
            name="ReportsFilterForm"
            initialValues={{ 
                timeframe: searchParams.has('timeframe') ? (searchParams.getAll('timeframe').map((time: any) => time === 'undefined' ? undefined : moment(time))) : [moment().startOf('week'), moment().endOf('week')]
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >

            <Form.Item
                label={t('reports.timeframe.title')}
                name="timeframe"
                rules={[{ required: true, message: 'Välj datum' }]}
            >
                <RangePicker
                    ranges={{
                        'Idag': [moment(), moment()],
                        'Denna vecka': [moment().startOf('week'), moment().endOf('week')],
                        'Denna månad': [moment().startOf('month'), moment().endOf('month')],
                    }}
                />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                {t('common.update')}
                </Button>
            </Form.Item>
        </Form>
    )
};

export default Timeilter;