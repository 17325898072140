import { Tabs } from 'antd';
import React, {FC} from 'react';
import { NavLink, useParams } from 'react-router-dom';
import ClientList from './components/ClientList';
import TaskList from './components/TaskList';
import { useTranslation } from 'react-i18next';
import ExpenseCategoryList from './components/ExpenseCategoryList';

const { TabPane } = Tabs;

const Manage: FC = () => {
    let { company_key, type } = useParams();
    const { t } = useTranslation();

    return (
        <Tabs activeKey={type}>
            <TabPane tab={<NavLink to="../tasks">{t('manage.tasks.title')}</NavLink>} key="tasks">
                <TaskList companyKey={company_key}/>
            </TabPane>
            <TabPane tab={<NavLink to="../expenses">{t('manage.expenseCategories.title')}</NavLink>} key="expenses">
                <ExpenseCategoryList companyKey={company_key}/>
            </TabPane>
            <TabPane tab={<NavLink to="../clients">{t('manage.clients.title')}</NavLink>} key="clients">
                <ClientList companyKey={company_key}/>
            </TabPane>
        </Tabs>
    )
};
export default Manage;