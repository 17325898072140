import React, {FC, useEffect, useState} from 'react';
import { Alert, Button, Dropdown, Menu, Table } from 'antd';
import BudgetProgressBar from './BudgetProgressBar';
import { DownOutlined, StopOutlined, CheckCircleOutlined, WarningOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {useProjects} from '../../../../hooks/useProjects';
import { Project } from '../../../../models/Project';
import { useClients } from '../../../../hooks/useClients';
import ProjectEdit from '../Project/ProjectEdit';
import { useTranslation } from 'react-i18next';
import { Client } from '../../../../models/Client';

interface ProjectListProps {
    companyKey: string,
    filter: string
}

const ProjectList: FC<ProjectListProps> = ({companyKey, filter}) => {
    const [errorProjects, loadingProjects, projects, updateData, saveLoading] = useProjects(companyKey);
    const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
    const [, loadingClients, clients] = useClients(companyKey);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingItem, setEditingItem] = useState<Project>();
    const { t } = useTranslation();
    const [tableData, setTableData] = useState<any>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    useEffect(() => {
        if(projects) {
            const _filteredProjects = projects
            .filter((mission: Project) => {
                if(filter === 'active' || filter === 'closed') {
                    return mission.status === filter
                } else if(filter === 'budget' || filter === 'nonBudget') {
                    return mission.status === 'active' && mission.hasBudget === (filter === 'budget' ? true : false)
                }
                return true;
                
            })
            setFilteredProjects(_filteredProjects);
        }
    },[projects, filter]);

    useEffect(() => {
        if(filteredProjects && clients) {
            var foundClients: Client[] = [];
            filteredProjects.forEach(project => {
                const foundClient = clients.find(client => client.id === project.clientId);
                if(foundClient && !foundClients.includes(foundClient)) {
                    foundClients.push(foundClient);
                }
            });
            foundClients.sort((a, b) => (a.name < b.name ? -1 : 1));
            setTableData(
                foundClients.map(client => {
                    const clientProjects = filteredProjects.sort((a, b) => (a.tag > b.tag ? -1 : 1)).filter(project => project.clientId === client.id);
                    return {
                        ...client,
                        children: clientProjects
                    }
                })
            );
            setExpandedRowKeys(foundClients.map(client => client.id));
        }
    }, [filteredProjects, clients])

    const setStatus = ((project: Project, status: string) => {
        updateData(project.id, {
            status: status
        })
    })

    const menu = (project: Project) => (
        <Menu>
            <Menu.Item key="show">
                <Link to={project.id}>{t('projects.actions.view')}</Link>
            </Menu.Item>
            <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => {setEditingItem(project); setIsModalVisible(true);}}>
                {t('projects.actions.edit')}
                </Menu.Item>
            {project.status === 'active' &&
                <Menu.Item key="close" icon={<StopOutlined />} onClick={() => setStatus(project, 'closed')}>
                    {t('projects.actions.close')}
                </Menu.Item>
            }
            {project.status === 'closed' &&
                <Menu.Item key="open" icon={<CheckCircleOutlined />} onClick={() => setStatus(project, 'active')}>
                    {t('projects.actions.open')}
                </Menu.Item>
            }
        </Menu>
    );

    const columns = [
        {
            title: t('projects.tag.title'),
            dataIndex: 'tag',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {
                        children: <strong>{record.name}</strong>,
                        props: {
                            colSpan: 7,
                        },
                    }
                }
                return text;
            }
        },
        {
            title: t('projects.label.title'),
            dataIndex: 'label',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const project = record as Project;
                return (
                    <Link to={project.id}>{text}</Link>
                )
            }
        },
        {
            title: t('projects.budget.title'),
            dataIndex: 'budget',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const project = record as Project;
                return (
                    project.hasBudget ? parseFloat(text).toFixed(2) : null
                )
            }
        },
        {
            title: t('projects.totalSpent.title'),
            dataIndex: 'totalSpent',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                return (
                    text ? parseFloat(text).toFixed(2) : '0.00'
                )
            }
        },
        {
            title: '',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const project = record as Project;
                return (
                    project.hasBudget ? (
                        <div style={{minWidth: 200}}>
                            <BudgetProgressBar project={project} />
                        </div>
                    ):(
                        null
                    )
                )
            }
        },
        {
            title: t('projects.remaining.title'),
            dataIndex: 'remaining',
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const project = record as Project;
                return (
                    project.hasBudget ? parseFloat(text).toFixed(2) : null
                )
            }
        },
        {
            render: (text: string, record: any) => {
                if (record.children) {
                    return {props: {colSpan: 0}}
                }
                const project = record as Project;
                return (
                    <Dropdown overlay={menu(project)}>
                        <Button>
                            {t('projects.actions.title')} <DownOutlined />
                        </Button>
                    </Dropdown>
                )
            }
        }
    ];
    
    if(errorProjects) {
        return (
            <Alert
                message="Error"
                description={errorProjects.message}
                type="error"
                showIcon
            />
        )
    }

    return (
        <>
            <Table 
                dataSource={tableData}
                columns={columns}
                expandable={{
                    expandRowByClick: true,
                    expandedRowKeys: expandedRowKeys,
                    onExpandedRowsChange: (keys) => setExpandedRowKeys([...keys] as string[])
                }}
                loading={loadingProjects || loadingClients}
                rowKey='id'
                pagination={false}
            />
            <ProjectEdit visible={isModalVisible} companyKey={companyKey} item={editingItem} onClose={() => {setIsModalVisible(false); setEditingItem(undefined)}} updateData={updateData} loading={saveLoading}/>
        </>
    )
};

export default ProjectList;