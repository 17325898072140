import { Form, Input, Modal, Spin } from 'antd';
import React, {FC, useEffect} from 'react';
import { Company } from '../../../models/Company';
import { useTranslation } from 'react-i18next';
import { useCompanyTemplates } from '../../../hooks/useCompanyTemplates';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface CompanyEditProps {
    visible: boolean,
    item?: Company | undefined,
    onClose: () => void,
    createData?: (values: any) => Promise<void>,
    updateData?: (id: string, values: any) => Promise<void>,
    loading: boolean
}

const CompanyEdit: FC<CompanyEditProps> = ({visible, item, onClose, createData, updateData, loading}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [errorTemplates, loadingTemplates, dataTemplates ] = useCompanyTemplates();
    
    useEffect(() => {
        if(form) {
            form.resetFields();
        }
    },[item, form])
    
    const onSubmit = async (values: any) => {
        form
        .validateFields()
        .then(async values => {
            if(item) {
                if(updateData)
                    await updateData(item.id, values);
            } else {
                if(createData) {
                    if(dataTemplates && dataTemplates.length > 0) { 
                        values.templateId = dataTemplates[0].id;
                    }
                    await createData(values);
                }
            }
            onClose();
        })
        .catch(info => {
            console.log('Validate Failed:', info);
        });
    };
    
    const handleCancel = () => {
        onClose();
    }

    return (
        <Modal 
            title={item ? item.name : t('common.add')} 
            visible={visible} 
            confirmLoading={loading}
            onCancel={handleCancel}
            okButtonProps={{form:'companyEdit', htmlType: 'submit'}}
        >
            {loadingTemplates ? <Spin /> : (
            <Form
                {...layout}
                form={form}
                name="companyEdit"
                initialValues={item ? item : {
                    isActive: true
                }}
                onFinish={onSubmit}
            >
                <Form.Item
                    label={t('company.name.title')}
                    name="name"
                    rules={[{ required: true, message: t('company.name.message')}]}
                >
                    <Input autoFocus />
                </Form.Item>
                
            </Form>
            )}
        </Modal>
    )
};
export default CompanyEdit;