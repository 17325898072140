import { Checkbox, Form, Input, Modal } from 'antd';
import React, {FC, useEffect} from 'react';
import { Client } from '../../../../models/Client';
import { useTranslation } from 'react-i18next';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface ClientEditProps {
    visible: boolean,
    item?: Client | undefined,
    onClose: () => void,
    createData: (values: any) => Promise<void>,
    updateData?: (id: string, values: any) => Promise<void>,
    loading: boolean
}

const ClientEdit: FC<ClientEditProps> = ({visible, item, onClose, createData, updateData, loading}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
        if(form) {
            form.resetFields();
        }
    },[item, form])
    
    const onSubmit = async (values: any) => {
        form
        .validateFields()
        .then(async values => {
            if(item) {
                if(updateData) {
                    await updateData(item.id, values);
                }
            } else {
                await createData(values);
            }
            onClose();
        })
        .catch(info => {
            console.log('Validate Failed:', info);
        });
    };
    
    const handleCancel = () => {
        onClose();
    }

    return (
        <Modal 
            title={item ? item.name : t('common.add')} 
            visible={visible} 
            confirmLoading={loading}
            forceRender
            onCancel={handleCancel}
            okButtonProps={{form:'clientEdit', htmlType: 'submit'}}
        >
            <Form
                {...layout}
                form={form}
                name="clientEdit"
                initialValues={item ? item : {
                    isActive: true
                }}
                onFinish={onSubmit}
            >
                <Form.Item
                    label={t('manage.clients.name.title')}
                    name="name"
                    rules={[{ required: true, message: t('client.name.message')}]}
                >
                    <Input autoFocus />
                </Form.Item>
                
                <Form.Item
                    label={t('manage.clients.isActive.title')}
                    name="isActive"
                    valuePropName="checked"
                    hidden={!item}
                >
                    <Checkbox />
                </Form.Item>

            </Form>
            
        </Modal>
    )
};
export default ClientEdit;