import { Divider } from 'antd';
import React, {FC, useState} from 'react';
import { useParams } from 'react-router-dom';
import ProjectFilter from './components/ProjectFilter';
import ProjectList from './components/ProjectList';

const Projects: FC = () => {
    const [selectedOption, setSelectedOption] = useState<string>('active');
    let { company_key } = useParams();

    return (
        <>
            <ProjectFilter companyKey={company_key} defaultValue={selectedOption} onChange={setSelectedOption}/>
            <Divider />
            <ProjectList filter={selectedOption} companyKey={company_key}/>
        </>
    )
};
export default Projects;