import * as firebase from "firebase/app";
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import PageLoading from '../common/PageLoading';

interface AuthenticatedRouteProps {
    element: React.ReactElement;
    redirectTo: string;
    path: string;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ element, redirectTo, path, ...props }) => {
    const [user, loading] = useAuthState(firebase.auth());
    const navigate = useNavigate();
    let location = useLocation();

    if(loading) {
        return <Route path={path} element={<PageLoading />} />
    }

    if(!user) {
        navigate(redirectTo+"?redirectTo="+location.pathname);
    }
    return <Route path={path} element={element} />
};

export default AuthenticatedRoute;