import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import svSE from 'antd/lib/locale/sv_SE';
import 'moment/locale/sv';
import moment from 'moment';
import { BrowserRouter as Router } from 'react-router-dom';

moment.locale('sv');

ReactDOM.render(
    <ConfigProvider locale={svSE}>
      <Router>
          <App />
      </Router>
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
