import React, {FC, useState} from 'react';
import { Alert, Button, Spin, Table } from 'antd';
import { CheckSquareOutlined, BorderOutlined, EditOutlined } from '@ant-design/icons';
import { Task } from '../../../../models/Task';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../../hooks/useCompany';
import CompanyEdit from '../../../Companies/components/CompanyEdit';

interface CompanySettingsProps {
    companyKey: string
}

const CompanySettings: FC<CompanySettingsProps> = ({companyKey}) => {
    const [error, loading, data, updateData, saveLoading] = useCompany(companyKey);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();

    if(error) {
        return (
            <Alert
                message="Error"
                description={error.message}
                type="error"
                showIcon
            />
        )
    }

    if(loading) {
        return <Spin />
    }
    
    return (
        <>
            <p>{data?.name}</p>
            <Button size="large"  type="primary" onClick={() => setIsModalVisible(true)}>{t('common.edit')}</Button>
            <CompanyEdit item={data} visible={isModalVisible} onClose={() => {setIsModalVisible(false)}} updateData={updateData} loading={saveLoading}/>
        </>
    )
};

export default CompanySettings;