import { Divider } from 'antd';
import React, {FC} from 'react';
import { useParams } from 'react-router-dom';
import TeamList from './components/TeamList';

const Team: FC = () => {
    let { company_key } = useParams();

    return (
        <>
            <Divider />
            <TeamList companyKey={company_key}/>
        </>
    )
};
export default Team;