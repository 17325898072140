import i18n from 'i18next';
import translationEn from './en/translation.json';
import translationSV from './sv/translation.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
    en: {
        translation: translationEn,
    },
    sv: {
        translation: translationSV,
    },
} as const;

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    fallbackLng: 'sv',
    resources
});