import { Modal, Button, Table, DatePicker, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import React, {FC, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';
import * as firebase from "firebase/app";
import { TimeReport } from '../../../models/TimeReport';
import TimeEdit from './components/TimeEdit';
import { useParams, useSearchParams } from 'react-router-dom';
import { useClients } from '../../../hooks/useClients';
import { useProjects } from '../../../hooks/useProjects';
import { useTasks } from '../../../hooks/useTasks';
import moment from 'moment';
import TimeFilter from './components/TimeFilter';
import { useReports } from '../../../hooks/useReports';

const dateFormat = 'YYYY-MM-DD';

const { RangePicker } = DatePicker;

const Time: FC = () => {
    let { company_key } = useParams();
    let [searchParams] = useSearchParams();
    const [user] = useAuthState(firebase.auth());
    const [userData, loadingUser] = useAuthState(firebase.auth());
    const [errorReports, loadingReports, reports, createData, updateData, deleteData, saveLoading] = useReports<TimeReport>(company_key, "timeReports", {
        timeframe: searchParams.has('timeframe') ? searchParams.getAll('timeframe') : [moment().startOf('week').format(dateFormat), moment().endOf('week').format(dateFormat)],
        team: [user?.uid]
    });
    const [, loadingProjects, projects] = useProjects(company_key);
    const [, loadingClients, clients, createClientData, , saveLoadingClient] = useClients(company_key);
    const [, loadingTasks, tasks] = useTasks(company_key);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingItem, setEditingItem] = useState<TimeReport>();
    const { t } = useTranslation();

    const onDelete = (idToDelete: string) => {
        Modal.confirm({
            title: t('common.delete.title'),
            content: t('common.delete.message'),
            onOk() {
                deleteData(idToDelete);
            }
        });
    }

    const columns = [
        {
            title: t('timeReports.date.title'),
            dataIndex: 'date'
        },
        {
            title: t('timeReports.projectId.title'),
            dataIndex: 'clientId',
            render: (text: string, record: TimeReport) => {
                const foundClient = clients.find(client => client.id === record.clientId);
                const foundProject = projects.find(project => project.id === record.projectId);
                const foundTask = tasks.find(task => task.id === record.taskId);
                return (
                    <>
                        <strong>{foundProject?.label}</strong> ({foundClient?.name})
                        <br />
                        {foundTask?.name}{record.note && (' - ' + record.note)}
                    </>
                )
            }
        },
        {
            title: t('timeReports.time.title'),
            dataIndex: 'hours',
            render: (text: number, record: TimeReport) => (
                <strong>{record.hours}:{record.minutes}</strong>
            )
        },
        {
            dataIndex: 'edit',
            render: (text: boolean, record: TimeReport) => (
                <Button onClick={() => {setEditingItem(record); setIsModalVisible(true);}}>
                    {t('common.edit')} <EditOutlined />
                </Button>
            )
        },
        {
            dataIndex: 'delete',
            render: (text: boolean, record: TimeReport) => (
                <Button icon={<DeleteOutlined />} onClick={() => onDelete(record.id)}/>
            )
        },
    ];
    
    return (
        <Space direction="vertical" style={{width: '100%'}}>
            <TimeFilter companyKey={company_key} />
            <Table 
                dataSource={reports}
                columns={columns}
                loading={loadingReports || loadingUser || loadingClients || loadingProjects || loadingTasks}
                rowKey='id'
                pagination={false}
            />
            <Button type="primary" onClick={() => setIsModalVisible(true)}>{t('common.add')}</Button>
            <TimeEdit visible={isModalVisible} companyKey={company_key} item={editingItem} onClose={() => {setIsModalVisible(false); setEditingItem(undefined)}} createData={createData} updateData={updateData} loading={saveLoading}/>
        </Space>
    )
};
export default Time;