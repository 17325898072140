import React, {FC, useState} from 'react';
import { Alert, Avatar, Button, Modal, notification, Space, Spin, Table, Tag } from 'antd';
import {useTeam} from '../../../../hooks/useTeam';
import { CompanyUser } from '../../../../models/CompanyUser';
import { CheckSquareOutlined, BorderOutlined, UserOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CompanyUserEdit from '../CompanyUser';
import { useSubscription, hasValidSubscription } from '../../../../hooks/useSubscription';
import { Link } from 'react-router-dom';
import { useInvite } from '../../../../hooks/useInvite';

interface TeamListProps {
    companyKey: string
}

const TeamList: FC<TeamListProps> = ({companyKey}) => {
    const [error, loading, team, createData, updateData, deleteData, saveLoading] = useTeam(companyKey);
    const [errorSubscription, loadingSubscription, dataSubscription] = useSubscription(companyKey);
    const [errorInvite, loadingInvite, invite, acceptInvite, resendInvite, deleteInvite, inviteSaveLoading] = useInvite();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingItem, setEditingItem] = useState<CompanyUser>();
    const { t } = useTranslation();

    const columns = [
        {
            title: t('team.companyUser.displayName'),
            dataIndex: 'displayName',
            render: (text: string, record: CompanyUser) => (
                <Space>
                    
                    <Avatar icon={<UserOutlined />} /> {text}
                    {record.isAdmin &&
                        <Tag color="processing">{t('team.companyUser.isAdmin')}</Tag>
                    }
                    {record.isInvite &&
                        <>
                            <Tag color="success">{t('team.companyUser.isInvite')}</Tag>
                            {inviteSaveLoading ? (
                                <Spin />
                            ):(
                                <Button type="link" onClick={() => {
                                    resendInvite(record.email).then(() => {
                                        notification.success({
                                            message: t('team.companyUser.isInvite')
                                        });
                                    })
                                }}>
                                    {t('team.companyUser.resendInvite')}
                                </Button>
                            )}
                        </>
                    }
                </Space>
            )
        },
        
        {
            title: t('team.companyUser.capacity.title'),
            dataIndex: 'capacity'
        },
        {
            title: t('manage.clients.isActive.title'),
            dataIndex: 'isActive',
            render: (text: boolean, record: CompanyUser) => (
                text ? <CheckSquareOutlined /> : <BorderOutlined />
            )
        },
        {
            dataIndex: 'edit',
            render: (text: boolean, record: CompanyUser) => (
                <Button onClick={() => {setEditingItem(record); setIsModalVisible(true);}}>
                    {t('common.edit')} <EditOutlined />
                </Button>
            )
        },
        {
            dataIndex: 'delete',
            render: (text: boolean, record: CompanyUser) => (
                record.isInvite &&
                <Button icon={<DeleteOutlined />} onClick={() => {
                    Modal.confirm({
                        title: t('common.delete.title'),
                        content: t('common.delete.message'),
                        onOk() {
                            deleteData(record.id); 
                            deleteInvite(record.email);
                        }
                    });
                }}/>
            )
        },
    ];
    
    if(error || errorSubscription) {
        return (
            <Alert
                message="Error"
                description={error ? error.message : errorSubscription.message}
                type="error"
                showIcon
            />
        )
    }

    const activeTeam = team.filter(companyUser => companyUser.isActive);
    
    return (
        <>
            <Table 
                dataSource={team}
                columns={columns}
                loading={loading || loadingSubscription}
                rowKey='id'
                pagination={false}
            />
            {!loadingSubscription &&
            <>
                <br />
                {hasValidSubscription(dataSubscription, activeTeam.length) ? 
                    <Button type="primary" onClick={() => setIsModalVisible(true)}>{t('common.add')}</Button>
                :
                    <>
                    <Alert
                        message={t('settings.subscription.upgrade')}
                        description={t('settings.subscription.limit')}
                        type="info"
                        showIcon
                        action={
                            <Button type="primary">
                                <Link to="../settings/subscription">{t('common.upgrade')}</Link>
                            </Button>
                        }
                    />
                    </>
                }
            </>}
            <CompanyUserEdit visible={isModalVisible} companyKey={companyKey} item={editingItem} onClose={() => {setIsModalVisible(false); setEditingItem(undefined)}} createData={createData} updateData={updateData} loading={saveLoading}/>
        </>
    )
};

export default TeamList;