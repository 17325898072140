import React, {FC} from 'react';
import { Button, Col, Row, Spin, Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';

const { Title, Text } = Typography;

const TermsPage: FC = () => {
    return (
        <div>
            <h1>Tidmer Terms of Service</h1>
            <p>This Tidmer Agreement is entered into by Ekorn Event AB and the entity or individual using the Service ("Customer"). This Agreement governs Customer access to and use of Tidmer (identified at <a href="https://tidmer.com/terms">https://tidmer.com/terms</a>) and is effective as of the date Customer clicks to accept the Agreement (the "Effective Date"). BY CLICKING THE "I ACCEPT" BUTTON, COMPLETING THE REGISTRATION PROCESS, OR USING THE SERVICE, YOU ACKNOWLEDGE THAT (I) YOU HAVE REVIEWED AND ACCEPT THIS AGREEMENT, (II) ARE AUTHORIZED TO ACT ON BEHALF OF, AND BIND TO THIS AGREEMENT, THE OWNER OF THIS Tidmer ACCOUNT. If you do not have the legal authority to enter this Agreement, do not understand this Agreement, or don't agree to any of its terms, please do not click to accept, or use Tidmer. In consideration of the foregoing, the parties agree as follows:
            </p>
            <h2>1. Provision of Tidmer</h2>
            <hr/>
            <p>1.1: Tidmer is a service provided by Ekorn Event AB.</p>

            <p>1.2: New Applications and Services. Ekorn Event AB may make new applications, tools, features or functionality related to Tidmer available from time to time the use of which may be contingent upon Customer's agreement to additional terms.</p>

            <p>1.3: Changes to the Services. Ekorn Event AB may make changes to the services at any time.</p>

            <p>1.4: Changes to this Agreement. Ekorn Event AB may make changes to this Agreement, including Fees (and any linked documents) from time to time. Ekorn Event AB will post any changes to this Agreement to <a href="https://tidmer.com/terms">https://tidmer.com/terms</a>, and all changes will be effective immediately after posting. Ekorn Event AB advises Customer to review the latest version of the Agreement at <a href="https://tidmer.com/terms">https://tidmer.com/terms</a> prior to using the Services to stay informed of the most current Agreement terms applicable to such use. The foregoing notwithstanding, unless otherwise noted by Ekorn Event AB, material changes to the Agreement will become effective 14 days after they are posted to <a href="https://tidmer.com/terms">https://tidmer.com/terms</a>, except if the changes apply to new functionality or are required by applicable law in which case they will be effective immediately after they are posted to <a href="https://tidmer.com/terms">https://tidmer.com/terms</a>. If Customer does not agree to any changes to the Agreement, Customer will stop using the Services. Any use of the Services after the posting and effective date of any changes to the Agreement to <a href="https://tidmer.com/terms">https://tidmer.com/terms</a> will constitute Customer's acceptance of the Agreement as modified.</p>

            <p>1.5: Data Storage. Unless otherwise provided for by the Services, Customer Data will be stored in Sweden ("Hosting Data Location"), except that the Hosting Data Location may not apply to Customer Data copied by Customer or a Customer's End User to another location or used with other Ekorn Event AB products and services.</p>

            <p>1.6: Transient Storage. Customer Data may be stored transiently or cached in any country in which Ekorn Event AB or its agents maintain facilities.</p>

            <h2>2. Payments Terms</h2>
            <hr/>
            <p>2.1: Free Quota. Certain Services are provided to Customer without charge, as applicable.</p>

            <p>2.2: Online Billing. At the end of a project using the Services, Ekorn Event AB will issue an electronic bill to Customer for all charges accrued based on (i) Customer's use of the Services during the previous project, and/or (ii) any Package Purchases selected. Customer will pay all Fees in the currency set forth in the invoice. Customer will pay all Fees in accordance with the payment terms applicable to the Fees. Ekorn Event AB's measurement of Customer's use of the Services is final. Ekorn Event AB has no obligation to provide multiple bills.</p>

            <p>2.3: Taxes. Customer is responsible for any Taxes, and Customer will pay Ekorn Event AB for the Services without any reduction for taxes. If Ekorn Event AB is obligated to collect or pay Taxes, the Taxes will be invoiced to Customer, unless Customer provides Ekorn Event AB with a timely and valid tax exemption certificate authorized by the appropriate taxing authority. If Customer is required by law to withhold any taxes from its payments to Ekorn Event AB, Customer must provide Ekorn Event AB with an official tax receipt or other appropriate documentation to support such withholding.</p>

            <p>2.4: Invoice Disputes & Refunds. To the fullest extent permitted by law, Customer waives all claims relating to Fees unless claimed within sixty days after charged (this does not affect any Customer rights with its credit card issuer). Refunds (if any) are at the discretion of Ekorn Event AB. Nothing in this Agreement obligates Ekorn Event AB to extend credit to any party.</p>

            <p>2.5: Delinquent Payments. Late payments may bear interest at the rate of 1.5% per month (or the highest rate permitted by law, if less). Ekorn Event AB reserves the right to suspend Customer's Account, for any late payments.</p>

            <p>2.6: Changes to Fees. Ekorn Event AB may change Fees at any time.</p>

            <h2>3. Restrictions</h2>
            <hr/>
            <p>3.1: Compliance. Customer is solely responsible for its Customer Data and for making sure its (and end users') use of the Services comply with the terms of this Agreement. Customer is responsible for ensuring all end users comply with Customer's obligations under the Agreement.</p>

            <p>3.2: Application and No Multiple Accounts or Bills. Any Application must have material value independent from the Services. Ekorn Event AB has no obligation to provide multiple bills or Accounts to Customer under the Agreement.</p>

            <h2>4. Confidentiality</h2>
            <hr/>
            <p>Neither party will use or disclose the other party's Confidential Information without the other's prior written consent except for the purpose of performing its obligations under this Agreement or if required by law, regulation or court order; in which case, the party being compelled to disclose Confidential Information will give the other party as much notice as is reasonably practicable prior to disclosing the Confidential Information if permitted by law.</p>

            <h2>5. Term and Termination</h2>
            <hr/>
            <p>5.1: Agreement Term. This Agreement will remain in effect until terminated by either party. Ekorn Event AB reserves the right to terminate this Agreement with Customer for any reason and at any time without liability or other obligation to you.</p>

            <p>5.2: Termination of Services. Customer may stop using the Services at any time. Ekorn Event AB may discontinue the Services or any portion or feature for any reason at any time without liability to Customer, except as limited in any applicable deprecation policy.</p>

            <p>5.3: Effect of Termination. If the Agreement expires or is terminated, then: (i) the rights granted by one party to the other will immediately cease; (ii) all Fees (including Taxes) owed by Customer to Ekorn Event AB are immediately due upon receipt of the final invoice; (iii) Customer will cease all use of the Services; (iv) upon request, each party will use commercially reasonable efforts to return or destroy all Confidential Information of the other party.</p>

            <h2>6. Disclaimer</h2>
            <hr/>
            EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EKORN EVENT AB AND ITS SUPPLIERS DO NOT MAKE ANY WARRANTY OF ANY KIND RELATED TO THE SERVICES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE AND NONINFRINGEMENT. EKORN EVENT AB AND ITS SUPPLIERS ARE NOT RESPONSIBLE OR LIABLE FOR THE DELETION OF OR FAILURE TO STORE ANY CUSTOMER DATA AND OTHER COMMUNICATIONS MAINTAINED OR TRANSMITTED THROUGH USE OF THE SERVICES. CUSTOMER IS SOLELY RESPONSIBLE FOR SECURING AND BACKING UP ITS PROJECT, AND CUSTOMER DATA. NEITHER EKORN EVENT AB, NOR ITS SUPPLIERS OR LICENSEES, WARRANT THAT THE OPERATION OF THE SERVICES WILL BE ERROR-FREE, MALWARE- FREE, OR UNINTERRUPTED.

            <h2>7. Limitation of Liability</h2>
            <hr/>
            <p>7.1: Limitation on Indirect Liability. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW NEITHER EKORN EVENT AB NOR EKORN EVENT AB'S SUPPLIERS, WILL BE LIABLE UNDER THIS AGREEMENT FOR LOST REVENUES OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF EKORN EVENT AB OR ITS SUPPLIERS KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF DIRECT DAMAGES DO NOT SATISFY A REMEDY.</p>

            <p>7.2: Limitation on Amount of Liability. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER EKORN EVENT AB NOR EKORN EVENT AB'S SUPPLIERS, MAY BE HELD LIABLE UNDER THIS AGREEMENT FOR MORE THAN THE AMOUNT PAID BY CUSTOMER TO EKORN EVENT AB FOR USE OF THE SERVICES DURING THE SIX (6) MONTHS PRIOR TO THE EVENT GIVING RISE TO LIABILITY.</p>

            <p>7.3: IN ALL CASES, EKORN EVENT AB, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY EXPENSE, LOSS, OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.</p>

            <p>7.4: Indemnification. Customer will defend and indemnify Ekorn Event AB and its Affiliates, officers, directors, and employees against all liabilities, damages, losses, costs, fees (including legal fees), and expenses relating to any allegation or third-party legal proceeding to the extent arising from: (i) any Application, Project, or Customer Data; or (ii) Customer's, or its end users', use of the Services. Ekorn Event AB and its Affiliates, officers, directors, and employees reserve the right, at their own expense, to assume the exclusive defense and control of any matter subject to indemnification by Customer.</p>

            <p>7.5: Remedies</p>

            <p>7.5.1: If Ekorn Event AB reasonably believes the Services might infringe a third party's Intellectual Property Rights, then Ekorn Event AB may, at its sole option and expense: (a) procure the right for Customer to continue using the Services; (b) modify the Services to make it non-infringing without materially reducing their functionality; or (c) replace the Services with a non-infringing, functionally equivalent alternative.</p>

            <p>7.5.2: If Ekorn Event AB does not believe the remedies in Section 7.5.1 are commercially reasonable, then Ekorn Event AB may suspend or terminate Customer's use of the Services.</p>

            <p>7.6: Sole Rights and Obligations. Without affecting either party's termination rights, this Section 7 states the parties' only rights and obligations under this Agreement for Intellectual Property Rights-related Allegations and third-party legal proceedings.</p>

            <h2>8. Miscellaneous</h2>
            <hr/>
            <p>8.1: Assignment. Neither party may assign any part of this Agreement without the written consent of the other, except to an Affiliate where: (a) the assignee has agreed in writing to be bound by the terms of this Agreement; (b) the assigning party remains liable for obligations under the Agreement if the assignee defaults on them; and (c) the assigning party has notified the other party of the assignment. Any other attempt to assign is void.</p>

            <p>8.2: Change of Control. If a party experiences a change of Control (for example, through a stock purchase or sale, merger, or other form of corporate transaction): (a) that party will give written notice to the other party within thirty (30) days after the change of Control; and (b) the other party may immediately terminate this Agreement any time between the change of Control and thirty days after it receives that written notice.</p>

            <p>8.3: Force Majeure. Neither party will be liable for failure or delay in performance to the extent caused by circumstances beyond its reasonable control.</p>

            <p>8.4: No Waiver. Neither party will be treated as having waived any rights by not exercising (or delaying the exercise of) any rights under this Agreement.</p>

            <p>8.5: Severability. If any term (or part of a term) of this Agreement is invalid, illegal or unenforceable, the rest of the Agreement will remain in effect.</p>

            <p>8.6: Entire Agreement. This Agreement sets out all terms agreed between the parties and supersedes all other agreements between the parties relating to its subject matter. In entering into this Agreement, neither party has relied on, and neither party will have any right or remedy based on, any statement, representation or warranty (whether made negligently or innocently), except those expressly set out in this Agreement. The terms located at a URL referenced in this Agreement and the Documentation are incorporated by reference into the Agreement. After the Effective Date, Ekorn Event AB may provide an updated URL in place of any URL in this Agreement.</p>

            <p>8.7: Definitions</p>

            <p>"Account" means Customer's Tidmer-authorized and approved account to use the Services.</p>

            <p>"Affiliate" means any entity that directly or indirectly Controls, is Controlled by, or is under common Control with a party.</p>

            <p>"Allegation" means an unaffiliated third party's allegation.</p>

            <p>"Application(s)" means any web or other application Customer creates using the Services.</p>

            <p>"Confidential Information" means information that one party (or an Affiliate) discloses to the other party under this Agreement, and which is marked as confidential or would normally under the circumstances be considered confidential information. It does not include information that is independently developed by the recipient, is rightfully given to the recipient by a third party without confidentiality obligations, or becomes public through no fault of the recipient. Subject to the preceding sentence, Customer Data is considered Customer's Confidential Information.</p>

            <p>"Control" means control of greater than fifty percent of the voting rights or equity interests of a party.</p>

            <p>"Customer Data" means content provided to Ekorn Event AB by Customer (or at its direction) via the Services.</p>

            <p>"Documentation" means the Tidmer documentation (as may be updated from time to time) in the form generally made available by Ekorn Event AB to its customers for use of the Services (including any related information provided via email).</p>

            <p>"End Users" means the individuals that Customer permits to use an Application or other services from Customer using the Services.</p>

            <p>"Fees" means the applicable fees for each Service and any applicable Taxes. The Fees for each Service are set forth here: https://tidmer.com.</p>

            <p>"Ekorn Event AB" means Ekorn Event AB, with office at Sjöängsvägen 23B, 14264 Trångsund, Sweden.</p>

            <p>"Intellectual Property Rights" means current and future worldwide rights under patent, copyright, trade secret, trademark, or moral rights laws, and other similar rights.</p>

            <p>"Package Purchase" means Customer's commitment to purchase a specified package of the Services over a specified period of time, whether Customer uses those Services or not.</p>

            <p>"Project" means a grouping of computing, storage, and API resources for Customer (normally a competition event), and via which Customer may use the Services.</p>

            <p>"Taxes" means any duties, customs fees, or taxes (other than Ekorn Event AB's income tax) associated with the purchase of the Services, including any related penalties or interest.</p>
        </div>

    )
};

const styles = StyleSheet.create({
    container: {
        padding: 20
    }
});

export default TermsPage;