import React, {FC, useEffect, useState} from 'react';
import { Button, Checkbox, Dropdown, InputNumber, Menu, Table } from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useTasks } from '../../../../../hooks/useTasks';
import { Project } from '../../../../../models/Project';
import { useTaskAssignments } from '../../../../../hooks/useTaskAssignments';
import { TaskAssignment } from '../../../../../models/TaskAssignment';

interface ProjectEditTaskListProps {
    companyKey: string,
    item?: Project,
    showRate: boolean,
    onChangeItems: (tasks: TaskAssignment[]) => void
}

const ProjectEditTaskList: FC<ProjectEditTaskListProps> = ({companyKey, item, showRate, onChangeItems}) => {
    const [, loadingTasks, defaultTasks] = useTasks(companyKey);
    const [, loadingTaskAssignments, taskAssignments] = useTaskAssignments(companyKey, item?.id);
    const [tasks, setTasks] = useState<TaskAssignment[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        if(item) {
            setTasks(taskAssignments.map(task => {
                const foundDefaultTask = defaultTasks.find(defaultTask => defaultTask.id === task.id);
                return ({
                    ...task,
                    name: foundDefaultTask ? foundDefaultTask.name : '...'
                })
            }))
        } else {
            setTasks(defaultTasks
                .filter(task => task.isDefault)
                .map(task => ({
                    id: task.id,
                    billable: task.billableByDefault,
                    hourlyRate: task.defaultHourlyRate,
                    name: task.name
                }))
            )
        }
    },[item, defaultTasks, taskAssignments])

    useEffect(() => {
        onChangeItems(tasks);
    },[tasks, onChangeItems])

    const onChange = (id: string, key: any, value: any) => {
        setTasks(tasks.map(task => {
            if(task.id === id) {
                return ({
                    ...task,
                    [key]: value
                });
            }
            return task;
        }))
    }

    const onDelete = (id: string) => {
        setTasks(tasks.filter(task => task.id !== id));
    }

    const columns = [
        {
            dataIndex: 'delete',
            render: (text: boolean, record: TaskAssignment) => (
                <Button icon={<DeleteOutlined />} onClick={() => onDelete(record.id)}/>
            )
        },
        {
            title: t('manage.tasks.name.title'),
            dataIndex: 'name'
        },
        {
            title: t('manage.tasks.billableByDefault.title'),
            dataIndex: 'billable',
            render: (text: boolean, record: TaskAssignment) => (
                <Checkbox checked={text} onChange={(e) => onChange(record.id, 'billable', e.target.checked)}/>
            )
        },
        showRate ? {
            title: t('manage.tasks.defaultHourlyRate.title'),
            dataIndex: 'hourlyRate',
            render: (text: number, record: TaskAssignment) => (
                <InputNumber value={text} onChange={(value) => onChange(record.id, 'hourlyRate', value)}/>
            )
        }:{},
    ];

    function handleMenuClick(event: any) {
        const taskToAdd = defaultTasks.find(task => task.id === event.key);
        if(taskToAdd) {
            setTasks([
                ...tasks,
                {
                    id: taskToAdd.id,
                    billable: taskToAdd.billableByDefault,
                    hourlyRate: taskToAdd.defaultHourlyRate,
                    name: taskToAdd.name
                }
            ])
        }
    }

    const addTaskMenu = () => {
        const existingIds = defaultTasks.map(task => task.id);
        const updateIds = tasks.map(task => task.id);
        const addableIds = existingIds.filter(id => !updateIds.includes(id));
        return (
            <Menu onClick={handleMenuClick}>
                {defaultTasks.filter(task => addableIds.includes(task.id)).map(task => (
                    <Menu.Item key={task.id}>
                        {task.name}
                    </Menu.Item>
                ))}
            </Menu>
        )
    };
    
    return (
        <>
            <Table 
                dataSource={tasks}
                columns={columns}
                loading={loadingTasks || loadingTaskAssignments}
                rowKey='id'
                pagination={false}
            />
            <br />
            <Dropdown overlay={addTaskMenu}>
                <Button disabled={loadingTasks || loadingTaskAssignments}>
                    {t('common.add')} <DownOutlined />
                </Button>
            </Dropdown>
        </>
    )
};

export default ProjectEditTaskList;