import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";
import { ProjectUser } from '../models/ProjectUser';

export const useProjectUsers = (companyId: string, projectId: string | undefined) => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ProjectUser[]>([]);
    const [saveLoading, setSaveLoading] = useState(false);

    const assignData = async (projectToAssign: string, idToAssign: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .doc(projectToAssign)
            .collection('projectUsers')
            .doc(idToAssign)
            .set({
                ...values,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdByUserId: firebase.auth().currentUser?.uid
            })
        setSaveLoading(false);
    }

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .doc(projectId)
            .collection('projectUsers')
            .doc(idToUpdate)
            .update(values)
        setSaveLoading(false);
    }

    const deleteData = async (idToDelete: string) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .doc(projectId)
            .collection('projectUsers')
            .doc(idToDelete)
            .delete()
        setSaveLoading(false);
    }

    useEffect(() => {
        if(!companyId || !projectId) {
            setLoading(false);
            return;
        }

        const onSnapshot = (snapshot: any) => {
            setData(
                snapshot.docs.map((doc: any) => ({
                    ...doc.data() as ProjectUser,
                    id: doc.id
                }))
            );
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('projects')
        .doc(projectId)
        .collection('projectUsers')
        .onSnapshot(onSnapshot, (error: any) => {
            setError(error)
        });

        return unsubscribe;

        
    }, [companyId, projectId]);

    return [error, loading, data, assignData, updateData, deleteData, saveLoading] as const;
};