import { useEffect, useState } from 'react';
import * as firebase from "firebase/app";
import { CompanyUser } from '../models/CompanyUser';

export const useTeam = (companyId: string) => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<CompanyUser[]>([]);
    const [saveLoading, setSaveLoading] = useState(false);

    const createData = async (values: any) => {
        setSaveLoading(true);
        const docRef = await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('companyUsers')
            .add({
                ...values,
                isInvite: true,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdByUserId: firebase.auth().currentUser?.uid
            })
        await firebase
            .firestore()
            .collection('invites')
            .add({
                email: values.email,
                companyId: companyId,
                companyUserId: docRef.id,
                acceptedInvite: false,
                sendInvitation: true,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdByUserId: firebase.auth().currentUser?.uid
            })
        setSaveLoading(false);
    }

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('companyUsers')
            .doc(idToUpdate)
            .update({
                ...values,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedByUserId: firebase.auth().currentUser?.uid
            })
        
        const companyDocRef = await firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .get();
        const activeUsers = companyDocRef.data()?.activeUsers;
        if(values.isActive === false && activeUsers && activeUsers.includes(idToUpdate)) {
            await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .update({
                activeUsers: firebase.firestore.FieldValue.arrayRemove(idToUpdate),
            });
        } else if(values.isActive === true && activeUsers && !activeUsers.includes(idToUpdate)) {
            await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .update({
                activeUsers: firebase.firestore.FieldValue.arrayUnion(idToUpdate),
            });
        }     
        
        setSaveLoading(false);
    }

    const deleteData = async (idToDelete: string) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection('companyUsers')
            .doc(idToDelete)
            .delete()
        setSaveLoading(false);
    }

    useEffect(() => {
        if(!companyId) {
            return;
        }

        const onSnapshot = (snapshot: any) => {
            setData(
                snapshot.docs.map((doc: any) => ({
                    ...doc.data() as CompanyUser,
                    id: doc.id
                }))
            );
            setLoading(false);
        };

        const unsubscribe = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('companyUsers')
        .onSnapshot(onSnapshot, (error: any) => {
            setError(error)
        });
    
        return unsubscribe;

    }, [companyId]);

    return [error, loading, data, createData, updateData, deleteData, saveLoading] as const;
}