import React, {FC} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import PageLoading from '../../common/PageLoading';
import { Alert, Button, Space, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const onClickLogOut = () => 
    firebase.auth().signOut()

const SignInPage: FC = () => {
    const [user, loading] = useAuthState(firebase.auth());
    let [searchParams] = useSearchParams();
    const redirectTo = searchParams.get("redirectTo");
    const { t } = useTranslation();
    
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: redirectTo ? redirectTo : '/companies',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: true
            },
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            "apple.com"
        ]
    };
    
    if(loading) {
        return (
                <PageLoading />
        )
    }

    return (
        <>
            {user ? (
                <>
                    <Alert
                        message={t('user.profile.title')}
                        description={t('user.signedInAs', {email: user.email})}
                        type="info"
                        showIcon
                    />
                    <br />
                    <Space direction="vertical"/>
                    <Button onClick={onClickLogOut}>{t('user.signOut')}</Button>
                </>
            ):(
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
            )}
            
        </>

    )
};

export default SignInPage;