import React, {FC} from 'react';
import { Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { Link, Route, Routes } from 'react-router-dom';
import MainNavigation from './MainNavigation';
import ConsoleNavigation from './ConsoleNavigation';

const { Title } = Typography;

const Header: FC = () => (
    <>
        <div className={css(styles.logo)}>
            <Link to="/"><Title className={css(styles.logoText)} level={2}>Tidmer</Title></Link>
        </div>
        <Routes>
            <Route path="/*" element={<MainNavigation />} />
            <Route path="console/:company_key/*" element={<ConsoleNavigation />} />
        </Routes>
    </>
)

const styles = StyleSheet.create({
    logo: {
        float: 'left',
        height: '100%',
        marginLeft: 30,
        marginRight: 10
    },
    logoText: {
        lineHeight: '64px'
    }
});

export default Header;