import React, {FC} from 'react';
import { Spin } from 'antd';
import { StyleSheet, css } from 'aphrodite';

interface PageLoadingProps {
    
}

const PageLoading: FC = () => (
    <div className={css(styles.container)}>
        <Spin size="large" />
    </div>
)

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 300
    }
});

export default PageLoading;