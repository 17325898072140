import React, {FC, useEffect, useState} from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../../../models/Project';
import { useTeam } from '../../../../../hooks/useTeam';
import { ProjectUser } from '../../../../../models/ProjectUser';
import { useProjectUsers } from '../../../../../hooks/useProjectUsers';
import { TimeReport } from '../../../../../models/TimeReport';
import moment from 'moment';

interface ProjectUserListProps {
    companyKey: string,
    item: Project,
    reports: TimeReport[]
}

const ProjectUserList: FC<ProjectUserListProps> = ({companyKey, item, reports}) => {
    const [, loadingTeam, team] = useTeam(companyKey);
    const [, loadingProjectUsers, projectUsers] = useProjectUsers(companyKey, item.id);
    const [users, setUsers] = useState<ProjectUser[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setUsers(projectUsers.map(user => {
            const foundTeamUser = team.find(team => team.id === user.id);
            var totalHours: number = 0;
            var totalMinutes: number = 0;
            const timeReports = reports.filter(report => report.forUser === user.id);
            timeReports.forEach(timeReport => {
                totalHours += +timeReport.hours;
                totalMinutes += +timeReport.minutes;
            });
            var totalTimeMoment = moment.duration(totalHours, 'hours');
            totalTimeMoment.add(totalMinutes, 'minutes');
            return {
                ...user,
                totalTime: totalTimeMoment.asHours().toFixed(2),
                displayName: foundTeamUser ? foundTeamUser.displayName : '...'
            };
        }));
    },[item, reports, team, projectUsers])

    const columns = [
        {
            title: t('team.companyUser.displayName'),
            dataIndex: 'displayName'
        },
        {
            title: t('reports.totalTime.title'),
            dataIndex: 'totalTime'
        }
    ];

    return (
        <Table 
            dataSource={users}
            columns={columns}
            loading={loadingTeam || loadingProjectUsers}
            rowKey='id'
            pagination={false}
        />
    )
};

export default ProjectUserList;