import React, {FC} from 'react';
import { Avatar, Menu, Modal, Spin } from 'antd';
import * as firebase from "firebase/app";
import { useAuthState } from 'react-firebase-hooks/auth';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;

interface UserMenuProps {
    style: any
}

const UserMenu: FC<UserMenuProps> = (props) => {
    const [user, loading] = useAuthState(firebase.auth());
    const { t } = useTranslation();

    if(loading) {
        return (
            <Menu.Item {...props}>
                <Spin />
            </Menu.Item>
        )
    }

    const onLogout = () => {
        Modal.confirm({
            title: t('user.signOut'),
            content: t('user.signOutConfirm'),
            onOk() {
                firebase.auth().signOut()
            }
        });
    }

    if(user) {
        return (
            <SubMenu {...props} icon={<Avatar icon={<UserOutlined />} />}>
                <Menu.Item><Link to="/profile">{t('user.profile.title')}</Link></Menu.Item>
                <Menu.Item onClick={onLogout}>{t('user.signOut')}</Menu.Item>
            </SubMenu>
        )
    } else {
        return (
            <Menu.Item {...props}>
                <Link to="/signIn">{t('user.signIn')}</Link>
            </Menu.Item>
        )
    }
}

export default UserMenu;