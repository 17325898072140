import { Checkbox, Form, Input, Modal } from 'antd';
import React, {FC, useEffect} from 'react';
import { ExpenseCategory } from '../../../../models/ExpenseCategory';
import { useTranslation } from 'react-i18next';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface ExpenseCategoryEditProps {
    visible: boolean,
    item: ExpenseCategory | undefined,
    onClose: () => void,
    createExpenseCategory: (values: any) => Promise<void>,
    updateExpenseCategory: (id: string, values: any) => Promise<void>,
    loading: boolean
}

const ExpenseCategoryEdit: FC<ExpenseCategoryEditProps> = ({visible, item, onClose, createExpenseCategory, updateExpenseCategory, loading}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    
    useEffect(() => {
        if(form) {
            form.resetFields();
        }
    },[item, form])
    
    const onSubmit = async (values: any) => {
        form
        .validateFields()
        .then(async values => {
            if(item) {
                await updateExpenseCategory(item.id, values);
            } else {
                await createExpenseCategory(values);
            }
            onClose();
        })
        .catch(info => {
            console.log('Validate Failed:', info);
        });
    };
    
    const handleCancel = () => {
        onClose();
    }

    return (
        <Modal 
            title={item ? item.name : t('common.add')} 
            visible={visible} 
            confirmLoading={loading}
            forceRender
            onCancel={handleCancel}
            okButtonProps={{form:'expenseCategoryEdit', htmlType: 'submit'}}
        >
            <Form
                {...layout}
                form={form}
                name="expenseCategoryEdit"
                initialValues={item ? item : {
                    isDefault: true,
                    billableByDefault: true,
                    defaultHourlyRate: 0,
                    deactivated: false
                }}
                onFinish={onSubmit}
            >
                <Form.Item
                    label={t('manage.expenseCategories.name.title')}
                    name="name"
                    rules={[{ required: true, message: t('manage.expenseCategories.name.message') }]}
                >
                    <Input autoFocus />
                </Form.Item>

                <Form.Item
                    label={t('manage.expenseCategories.deactivated.title')}
                    name="deactivated"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>

            </Form>
            
        </Modal>
    )
};
export default ExpenseCategoryEdit;