import React, {FC, useState} from 'react';
import PageLoading from '../../common/PageLoading';
import { Alert, Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import * as firebase from "firebase/app";
import { useAuthState } from 'react-firebase-hooks/auth';

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 8 },
};

const Profile: FC = () => {
    const [user, loading, error] = useAuthState(firebase.auth());
    const [saveLoading, setSaveLoading] = useState(false);
    const { t } = useTranslation();
    
    if(loading) {
        return <PageLoading />
    }
    
    if(error) {
        return <Alert
            message="Error"
            description={t(error.message)}
            type="error"
            showIcon
        />
    }

    const onFinish = async(values: any) => {
        if(user) {
            setSaveLoading(true);
            await user.updateProfile({
                displayName: values.displayName
            })
            setSaveLoading(false);
        }
    }

    return (
        <Form
            {...layout}
            name="profile"
            initialValues={user}
            onFinish={onFinish}
            >
                <Form.Item
                    label={t('team.companyUser.displayName')}
                    name="displayName"
                    rules={[{ required: true }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={saveLoading}>
                    {t('common.update')}
                    </Button>
                </Form.Item>
            </Form>
    )
};
export default Profile;