import React, {FC} from 'react';
import { Alert, Statistic, Table } from 'antd';
import { CheckSquareOutlined, BorderOutlined, EditOutlined } from '@ant-design/icons';
import { useReports, useReportSummary } from '../../../../hooks/useReports';
import { useSearchParams } from 'react-router-dom';
import { useExpenseCategories } from '../../../../hooks/useExpenseCategories';
import { useProjects } from '../../../../hooks/useProjects';
import { Expense } from '../../../../models/Expense';
import { WarningOutlined } from '@ant-design/icons';
import { useClients } from '../../../../hooks/useClients';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useCompanyUsers } from '../../../../hooks/useCompanyUsers';

const dateFormat = 'YYYY-MM-DD';

interface ExpenseListProps {
    companyKey: string
}

const ExpenseList: FC<ExpenseListProps> = ({companyKey}) => {
    let [searchParams] = useSearchParams();
    const [errorReports, loadingReports, reports, summary] = useReports<Expense>(companyKey, "expenses", {
        timeframe: searchParams.has('timeframe') ? searchParams.getAll('timeframe') : [moment().startOf('week').format(dateFormat), moment().endOf('week').format(dateFormat)],
        clients: searchParams.getAll('clients'),
        projects: searchParams.getAll('projects'),
        tasks: searchParams.getAll('tasks'),
        team: searchParams.getAll('team')
    });
    const [, loadingCategories, categories] = useExpenseCategories(companyKey);
    const [, loadingProjects, projects] = useProjects(companyKey);
    const [, loadingClients, clients] = useClients(companyKey);
    const [, loadingCompanyUsers, companyUsers] = useCompanyUsers(companyKey);
    const reportSummary = useReportSummary<Expense>(reports, categories);
    const { t } = useTranslation();

    const columns = [
        {
            title: t('reports.clients.title'),
            dataIndex: 'clientId',
            render: (text: string, record: Expense) => {
                const project = projects.find(project => project.id === record.projectId);
                if(project) {
                    const client = clients.find(client => client.id === project.clientId);
                    if(client) {
                        return client.name
                    }
                }
                return <WarningOutlined />
            }
        },
        {
            title: t('reports.projects.title'),
            dataIndex: 'projectId',
            render: (text: string, record: Expense) => {
                const project = projects.find(project => project.id === record.projectId);
                if(project) {
                    return <><strong>{project.tag}</strong> {project.label}</>
                }
                return <WarningOutlined />
            }
        },
        {
            title: t('reports.tasks.title'),
            dataIndex: 'categoryId',
            render: (text: string, record: Expense) => {
                const category = categories.find(category => category.id === record.categoryId);
                if(category) {
                    return category.name
                }
                return <WarningOutlined />
            }
        },
        {
            title: t('reports.note.title'),
            dataIndex: 'description'
        },
        {
            title: t('reports.units.title'),
            dataIndex: 'units',
            render: (text: number, record: Expense) => {
                const category = categories.find(category => category.id === record.categoryId);
                if(category && category.unitPrice) {
                    return `${text} ${category.unitName}`
                }
                return text
            }
        },
        {
            title: t('reports.cost.title'),
            dataIndex: 'cost',
            render: (text: number, record: Expense) => {
                const category = categories.find(category => category.id === record.categoryId);
                if(category && category.unitPrice) {
                    return record.units * category.unitPrice + ' SEK'
                }
                return text && text + ' SEK'
            }
        },
        {
            title: t('reports.billable.title'),
            dataIndex: 'billable',
            render: (text: boolean) => (
                text ? <CheckSquareOutlined /> : <BorderOutlined />
            )
        },
        {
            title: t('reports.displayName.title'),
            dataIndex: 'displayName',
            render: (text: string, record: Expense) => {
                const companyUser = companyUsers.find(user => user.id === record.forUser);
                if(companyUser) {
                    return companyUser.displayName;
                }
                return <WarningOutlined />
            }
        },
        {
            title: t('reports.date.title'),
            dataIndex: 'date'
        },
    ];
    
    if(errorReports) {
        return (
            <Alert
                message="Error"
                description={errorReports.message}
                type="error"
                showIcon
            />
        )
    }
    
    return (
        <>
            {reports.length > 0 &&
                <Statistic title={t('reports.totalCost')} value={reportSummary?.totalCost} />
            }
            <br />
            <Table 
                dataSource={reports}
                columns={columns}
                size="small"
                loading={loadingReports || loadingCategories || loadingProjects || loadingClients || loadingCompanyUsers}
                rowKey='id'
                pagination={false}
            />
        </>
    )
};

export default ExpenseList;