import React, {FC, useEffect, useState} from 'react';
import { Menu, Spin } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import UserMenu from './UserMenu';
import { useCompanyUser } from '../../hooks/useCompanyUser';
import { useTranslation } from 'react-i18next';

const ConsoleNavigation: FC = () => {
    let { company_key } = useParams();
    const [, loading, user] = useCompanyUser(company_key);
    const [selectedKey, setSelectedKey] = useState<string>('projects');
    let location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        const parts: string[] = location.pathname.split('/');
        if(parts.length >= 4) {
            setSelectedKey(parts[3]);
        }
    }, [location])

    return (
        <Menu mode="horizontal" defaultSelectedKeys={['projects']} selectedKeys={[selectedKey]}>
            <Menu.Item key="time"><Link to="time">{t('timeReports.title')}</Link></Menu.Item>
            <Menu.Item key="projects"><Link to="projects">{t('projects.title')}</Link></Menu.Item>
            {loading &&
                <Menu.Item key="loading"><Spin /></Menu.Item>
            }
            {user && user.isAdmin &&
                <Menu.Item key="team"><Link to="team">{t('team.title')}</Link></Menu.Item>
            }
            {user && user.isAdmin &&
                <Menu.Item key="reports"><Link to="reports/detailedTime">{t('reports.title')}</Link></Menu.Item>
            }
            {user && user.isAdmin &&
                <Menu.Item key="manage"><Link to="manage/tasks">{t('manage.title')}</Link></Menu.Item>
            }
            <UserMenu key="user" style={{float: 'right'}}/>
            {user && user.isAdmin &&
                <Menu.Item key="settings" style={{float: 'right'}}><Link to="settings/company">{t('settings.title')}</Link></Menu.Item>
            }
        </Menu>
    )
}

export default ConsoleNavigation;