import { useEffect, useState } from 'react';
import * as firebase from "firebase/app";
import moment from 'moment';
import { ExpenseCategory } from '../models/ExpenseCategory';
import { TimeReport } from '../models/TimeReport';

export function useReports<T>(companyId: string, type: string, params: any) {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<T[]>([]);
    const [query, setQuery] = useState<firebase.firestore.Query>();
    const [saveLoading, setSaveLoading] = useState(false);

    const { timeframe, clients, projects, tasks, categories, team } = params;

    const createData = async (values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection(type)
            .add({
                ...values,
                companyId: companyId,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdByUserId: firebase.auth().currentUser?.uid
            })
        setSaveLoading(false);
    }

    const updateData = async (idToUpdate: string, values: any) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection(type)
            .doc(idToUpdate)
            .update({
                ...values,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedByUserId: firebase.auth().currentUser?.uid
            })
        setSaveLoading(false);
    }

    const deleteData = async (idToDelete: string) => {
        setSaveLoading(true);
        await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .collection(type)
            .doc(idToDelete)
            .delete()
        setSaveLoading(false);
    }

    useEffect(() => {
        if(!companyId) {
            return;
        }

        var newQuery = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection(type);
        
        if(timeframe && timeframe.length > 0) {
            if(timeframe[0] !== 'undefined' && timeframe[0] !== 'all_time') {
                newQuery = newQuery.where('date', '>=', timeframe[0]) as any;
            }
            if(timeframe.length > 1 && timeframe[1] !== 'undefined') {
                newQuery = newQuery.where('date', '<=', timeframe[1]) as any;
            }
        }

        if(clients && clients.length > 0) {
            newQuery = newQuery.where('clientId', '==', clients[0] ) as any;
        }

        if(projects && projects.length > 0) {
            newQuery = newQuery.where('projectId', '==', projects[0] ) as any;
        }

        if(tasks && tasks.length > 0) {
            newQuery = newQuery.where('taskId', '==', tasks[0] ) as any;
        }

        if(categories && categories.length > 0) {
            newQuery = newQuery.where('categoryId', '==', categories[0] ) as any;
        }

        if(team && team.length > 0) {
            newQuery = newQuery.where('forUser', '==', team[0] ) as any;
        }

        setQuery(newQuery);
    }, []);
    // TODO: Must update query on params change - causes infinte loop

    useEffect(() => {
        if(!query) {
            return;
        }
        
        var unsubscribe = query
        .orderBy('date')
        .onSnapshot(
            (snapshot) => {
                setData(
                    snapshot.docs.map((doc) => {
                        if(type === 'timeReports') {
                            const totalTime = moment.duration(doc.data().hours, 'hours').add(doc.data().minutes, 'minutes').asHours();
                            return {
                                ...doc.data() as T,
                                id: doc.id,
                                totalTime: totalTime
                            }
                        } else {
                            return {
                                ...doc.data() as T,
                                id: doc.id
                            }
                        }
                    })
                );
                setLoading(false);
            },
            (error) => {
                setError(error);
            }
        );

        return unsubscribe

    }, [query, type]);

    return [error, loading, data, createData, updateData, deleteData, saveLoading] as const;
}

interface ReportSummary {
    totalTime: string,
    totalCost: string,
    internalCost: string
};

export function useReportSummary<T>(reports: T[], list?: any[]) {
    const [data, setData] = useState<ReportSummary>();
    useEffect(() => {
        if(reports.length > 0) {
            var totalTime: number = 0;
            var totalCost: number = 0;
            reports.forEach((report: any) => {
                if(report.totalTime) {
                    totalTime += +report.totalTime;
                }
                if(report.cost) {
                    totalCost += +report.cost;
                } else if(report.units) {
                    const foundCategory: ExpenseCategory = list && list.find(item => item.id === report.categoryId);
                    if(foundCategory) {
                        totalCost += +(report.units * foundCategory.unitPrice);
                    }
                }
            })
            setData({
                totalTime: totalTime.toFixed(2),
                totalCost: totalCost.toFixed(2),
                internalCost: '0.00'
            });
        }
    },[reports]);

    return data;
}

export function getTotalHoursFromReports(reports: TimeReport[]) {
    var totalHours: number = 0;
    var totalMinutes: number = 0;
    reports.forEach(taskReport => {
        totalHours += +taskReport.hours;
        totalMinutes += +taskReport.minutes;
    });
    var totalTimeMoment = moment.duration(totalHours, 'hours');
    totalTimeMoment.add(totalMinutes, 'minutes');
    return totalTimeMoment.asHours();
}