import React, {FC} from 'react';
import { Divider, Layout } from 'antd';
import NavigationHeader from "../common/NavigationHeader";
import { StyleSheet, css } from 'aphrodite';
import { Link, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {version} from '../../../package.json';

const { Header, Footer, Content } = Layout;

interface PublicLayoutProps {
    children?: any
}

const PublicLayout: FC<PublicLayoutProps> = ({ children }) => {
    const { t } = useTranslation();

    return (
        <Layout className={css(styles.layout)}>
            <Header className={css(styles.header)}>
                <NavigationHeader />
            </Header>
            <Content className={css(styles.content)}>
                <Outlet />
            </Content>
            <Footer>
            <>
                Ekorn Event AB - { version }
                <Divider type="vertical" />
                <Link to="terms">{t('home.terms')}</Link>
                <Divider type="vertical" />
                <Link to="privacy">{t('home.privacy')}</Link>
            </>
                </Footer>
        </Layout>
    )
};

const styles = StyleSheet.create({
    layout: {
        background: '#fff',
        minHeight: 280
    },
    header: {
        padding: 0
    },
    content: {
        padding: 24
    }
});

export default PublicLayout;