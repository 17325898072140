import { Result } from "antd";
import * as firebase from "firebase/app";
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Route, useNavigate, useParams } from 'react-router-dom';
import PageLoading from '../common/PageLoading';
import { useCompanyUser } from "../hooks/useCompanyUser";
import { useTranslation } from 'react-i18next';

interface CompanyRouteProps {
    element?: React.ReactElement;
    redirectTo: string;
    path: string;
}

const CompanyRoute: React.FC<CompanyRouteProps> = ({ element, redirectTo, path, ...props }) => {
    let { company_key } = useParams();
    const [error, loading, user] = useCompanyUser(company_key);
    const { t } = useTranslation();

    if(loading) {
        return <Route path={path} element={<PageLoading />} />
    }
    
    if(!user || error) {
        return <Result
            status="403"
            title={t('common.forbidden.title')}
            subTitle={t('common.forbidden.message')}
        />
    }
    return <Route path={path} element={element}>{props.children}</Route>
};

export default CompanyRoute;