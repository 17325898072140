import React, {FC, useState} from 'react';
import { Alert, Button, Table } from 'antd';
import { CheckSquareOutlined, BorderOutlined, EditOutlined } from '@ant-design/icons';
import { useClients } from '../../../../hooks/useClients';
import { Client } from '../../../../models/Client';
import ClientEdit from './ClientEdit';
import { useTranslation } from 'react-i18next';

interface ClientListProps {
    companyKey: string
}

const ClientList: FC<ClientListProps> = ({companyKey}) => {
    const [error, loading, clients, createData, updateData, saveLoading] = useClients(companyKey);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingItem, setEditingItem] = useState<Client>();
    const { t } = useTranslation();

    const columns = [
        {
            title: t('manage.clients.name.title'),
            dataIndex: 'name'
        },
        {
            title: t('manage.clients.isActive.title'),
            dataIndex: 'isActive',
            render: (text: boolean, record: Client) => (
                text ? <CheckSquareOutlined /> : <BorderOutlined />
            )
        },
        {
            dataIndex: 'edit',
            render: (text: boolean, record: Client) => (
                <Button onClick={() => {setEditingItem(record); setIsModalVisible(true);}}>
                    {t('common.edit')} <EditOutlined />
                </Button>
            )
        }
    ];
    
    if(error) {
        return (
            <Alert
                message="Error"
                description={error.message}
                type="error"
                showIcon
            />
        )
    }
    
    return (
        <>
            <Table 
                dataSource={clients}
                columns={columns}
                loading={loading}
                rowKey='id'
                pagination={false}
            />
            <br />
            <Button type="primary" onClick={() => setIsModalVisible(true)}>{t('common.add')}</Button>
            <ClientEdit visible={isModalVisible} item={editingItem} onClose={() => {setIsModalVisible(false); setEditingItem(undefined)}} createData={createData} updateData={updateData} loading={saveLoading}/>
        </>
    )
};

export default ClientList;