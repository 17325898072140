import React, {FC, useEffect, useState} from 'react';
import { Button, Checkbox, Dropdown, InputNumber, Menu, Table } from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../../../models/Project';
import { useTeam } from '../../../../../hooks/useTeam';
import { ProjectUser } from '../../../../../models/ProjectUser';
import { useProjectUsers } from '../../../../../hooks/useProjectUsers';
import { useCompanyUser } from '../../../../../hooks/useCompanyUser';

interface ProjectEditUserListProps {
    companyKey: string,
    item?: Project,
    showRate: boolean,
    onChangeItems: (tasks: ProjectUser[]) => void
}

const ProjectEditUserList: FC<ProjectEditUserListProps> = ({companyKey, item, showRate, onChangeItems}) => {
    const [, loadingCurrentUser, currentUser] = useCompanyUser(companyKey);
    const [, loadingTeam, team] = useTeam(companyKey);
    const [, loadingProjectUsers, projectUsers] = useProjectUsers(companyKey, item?.id);
    const [users, setUsers] = useState<ProjectUser[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        if(item) {
            setUsers(projectUsers.map(user => {
                const foundTeamUser = team.find(team => team.id === user.id);
                return ({
                    ...user,
                    displayName: foundTeamUser ? foundTeamUser.displayName : '...'
                })
            }))
        } else {
            setUsers(team
                .filter(user => user.hasAccessToAllFutureMissions && user.isActive)
                .map(user => ({
                    id: user.id,
                    deactivated: false,
                    isProjectManager: user.hasAccessToAllFutureMissions || user.id === currentUser!.id,
                    hourlyRate: user.defaultHourlyRate,
                    displayName: user.displayName
                }))
            )
        }
    },[item, team, projectUsers, currentUser])

    useEffect(() => {
        onChangeItems(users);
    },[users, onChangeItems])

    const onChange = (id: string, key: any, value: any) => {
        setUsers(users.map(user => {
            if(user.id === id) {
                return ({
                    ...user,
                    [key]: value
                });
            }
            return user;
        }))
    }


    const onDelete = (id: string) => {
        setUsers(users.filter(user => user.id !== id));
    }

    const columns = [
        {
            dataIndex: 'delete',
            render: (text: boolean, record: ProjectUser) => (
                <Button icon={<DeleteOutlined />} onClick={() => onDelete(record.id)}/>
            )
        },
        {
            title: t('team.companyUser.displayName'),
            dataIndex: 'displayName'
        },
        {
            title: t('team.companyUser.isProjectManager'),
            dataIndex: 'isProjectManager',
            render: (text: boolean, record: ProjectUser) => (
                <Checkbox checked={text} onChange={(e) => onChange(record.id, 'isProjectManager', e.target.checked)}/>
            )
        },
        showRate ? {
            title: t('team.companyUser.defaultHourlyRate.title'),
            dataIndex: 'hourlyRate',
            render: (text: number, record: ProjectUser) => (
                <InputNumber value={text} onChange={(value) => onChange(record.id, 'hourlyRate', value)}/>
            )
        }:{},
    ];

    function handleMenuClick(event: any) {
        const userToAdd = team.find(user => user.id === event.key);
        if(userToAdd) {
            setUsers([
                ...users,
                {
                    id: userToAdd.id,
                    deactivated: false,
                    isProjectManager: userToAdd.hasAccessToAllFutureMissions || userToAdd.id === currentUser!.id,
                    hourlyRate: userToAdd.defaultHourlyRate,
                    displayName: userToAdd.displayName
                }
            ])
        }
    }

    const addUserMenu = () => {
        const existingIds = team.map(user => user.id);
        const updateIds = users.map(user => user.id);
        const addableIds = existingIds.filter(id => !updateIds.includes(id));
        return (
            <Menu onClick={handleMenuClick}>
                {team.filter(user => addableIds.includes(user.id)).map(user => (
                    <Menu.Item key={user.id} disabled={user.isInvite}>
                        {user.displayName}
                    </Menu.Item>
                ))}
            </Menu>
        )
    };
    
    return (
        <>
            <Table 
                dataSource={users}
                columns={columns}
                loading={loadingCurrentUser || loadingTeam || loadingProjectUsers}
                rowKey='id'
                pagination={false}
            />
            <br />
            <Dropdown overlay={addUserMenu}>
            <Button disabled={loadingCurrentUser || loadingTeam || loadingProjectUsers}>
                    {t('common.add')} <DownOutlined />
                </Button>
            </Dropdown>
        </>
    )
};

export default ProjectEditUserList;