import React, {FC, useEffect, useState} from 'react';
import { Table } from 'antd';
import { CheckSquareOutlined, BorderOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useTasks } from '../../../../../hooks/useTasks';
import { Project } from '../../../../../models/Project';
import { TimeReport } from '../../../../../models/TimeReport';
import { useTaskAssignments } from '../../../../../hooks/useTaskAssignments';
import { TaskAssignment } from '../../../../../models/TaskAssignment';
import { useCompanyUsers } from '../../../../../hooks/useCompanyUsers';
import { getTotalHoursFromReports } from '../../../../../hooks/useReports';
import { CompanyUser } from '../../../../../models/CompanyUser';
import { Link } from 'react-router-dom';

interface ProjectTaskListProps {
    companyKey: string,
    item: Project,
    reports: TimeReport[]
}

const ProjectTaskList: FC<ProjectTaskListProps> = ({companyKey, item, reports}) => {
    const [, loadingTasks, defaultTasks] = useTasks(companyKey);
    const [, loadingTaskAssignments, taskAssignments] = useTaskAssignments(companyKey, item.id);
    const [, loadingCompanyUsers, companyUsers] = useCompanyUsers(companyKey);
    const [billableTasks, setBillableTasks] = useState<TaskAssignment[]>([]);
    const [nonBillableTasks, setNonBillableTasks] = useState<TaskAssignment[]>([]);
    const { t } = useTranslation();
    
    useEffect(() => {
        const allTaskAssignments = taskAssignments.map(task => {
            const foundDefaultTask = defaultTasks.find(defaultTask => defaultTask.id === task.id);
            let taskUsers: CompanyUser[] = [];
            const taskReports = reports
                .filter(report => report.taskId === task.id)
                .map(report => {
                    const reportUser = companyUsers.find(user => user.id === report.forUser);
                    if(reportUser && !taskUsers.includes(reportUser)) {
                        taskUsers.push(reportUser)
                    }
                    return report;
                });
            taskUsers = taskUsers.map(user => {
                const taskUserReports = reports.filter(report => report.forUser === user.id);
                const taskUserHours = getTotalHoursFromReports(taskUserReports);
                
                return {
                    ...user,
                    totalTime: taskUserHours.toFixed(2),
                    taskId: task.id
                }
            })
            return {
                ...task,
                totalTime: getTotalHoursFromReports(taskReports).toFixed(2),
                name: foundDefaultTask ? foundDefaultTask.name : '...',
                children: taskUsers
            };
        });
        setBillableTasks(allTaskAssignments.filter(task => task.billable));
        setNonBillableTasks(allTaskAssignments.filter(task => !task.billable));
    },[item, reports, defaultTasks, taskAssignments, companyUsers])


    return (
        <>
            <ProjectTaskListTable title={t('reports.billable.title')} tableData={billableTasks} projectId={item.id} loading={loadingTasks || loadingTaskAssignments || loadingCompanyUsers} />
            <ProjectTaskListTable title={t('projects.billBy.none')} tableData={nonBillableTasks} projectId={item.id} loading={loadingTasks || loadingTaskAssignments || loadingCompanyUsers} />
        </>
    )
};

export default ProjectTaskList;

interface ProjectTaskListTableProps {
    title: string,
    tableData: any,
    projectId: string,
    loading: boolean
}

const ProjectTaskListTable: FC<ProjectTaskListTableProps> = ({title, tableData, projectId, loading}) => {
    const { t } = useTranslation();
    
    const columns = [
        {
            title: title,
            dataIndex: 'name',
            render: (text: string, record: any) => (
                record.children ?
                    <strong>{text}</strong>
                : record.displayName
            )
        },
        {
            title: t('reports.totalTime.title'),
            dataIndex: 'totalTime',
            render: (text: string, record: any) => (
                record.children ?
                <Link to={`../../reports/detailedTime?timeframe=all_time&projects=${projectId}&tasks=${record.id}`}>{text}</Link>
                :
                <Link to={`../../reports/detailedTime?timeframe=all_time&projects=${projectId}&tasks=${record.taskId}&team=${record.id}`}>{text}</Link>
            )
        }
    ];

    return (
        <Table 
            dataSource={tableData}
            columns={columns}
            expandable={{
                expandRowByClick: true
            }}
            loading={loading}
            rowKey='id'
            pagination={false}
        />
    )
};